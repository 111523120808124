import { axiosInstance } from '../Axios';
import {
	setModalEasebuzzBus,
	setModalPayBusOffline,
	storeData,
} from './UiReducer';
import axios from 'axios';

const { createSlice } = require('@reduxjs/toolkit');

const BusReducer = createSlice({
	name: 'busseatseller',
	initialState: {
		success: false,
		error: null,
		busCity: null,
		loading: false,
		busOrderId: null,
		busResult: null,
		seatLayout: null,
		loadingseat: false,
		addBooking: false,
		busBlock: false,
		bookingBus: false,
	},
	reducers: {
		cityBusRequest(state, action) {
			// state.loadingBus = true;
		},
		cityBusSuccess(state, action) {
			state.busCity = action.payload;
			// storeData('busCity', action.payload);
			state.error = null;
		},
		cityBusFail(state, action) {
			// state.loadingBus = false;
			state.error = action.payload;
		},
		addBusSearchRequest(state, action) {
			state.loading = true;
		},
		addBusSearchSuccess(state, action) {
			state.loading = false;
			state.busOrderId = action.payload;
			state.error = null;
		},
		addBusSearchFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		busSearchRequest(state, action) {
			state.loading = true;
		},
		busSearchSuccess(state, action) {
			state.loading = false;
			state.busResult = action.payload;
			state.error = null;
		},
		busSearchFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		seatLayoutRequest(state, action) {
			state.loadingseat = true;
		},
		seatLayoutSuccess(state, action) {
			state.loadingseat = false;
			state.seatLayout = action.payload;
			state.error = null;
		},
		seatLayoutFail(state, action) {
			state.loadingseat = false;
			state.error = action.payload;
		},
		addBusBookingRequest(state, action) {
			state.loading = true;
		},
		addBusBookingSuccess(state, action) {
			state.loading = false;
			state.addBooking = action.payload;
			state.error = null;
		},
		addBusBookingFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		busBlockRequest(state, action) {
			state.loading = true;
		},
		busBlockSuccess(state, action) {
			state.loading = false;
			state.busBlock = action.payload;
			state.error = null;
		},
		busBlockFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		busBookingRequest(state, action) {
			state.loading = true;
		},
		busBookingSuccess(state, action) {
			state.loading = false;
			state.bookingBus = action.payload;
			state.error = null;
		},
		busBookingFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		busBookingDetailsRequest(state, action) {
			state.loading = true;
		},
		busBookingDetailsSuccess(state, action) {
			state.loading = false;
			state.busBooingDetail = action.payload;
			state.error = null;
		},
		busBookingDetailsFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		debitbuspaymentRequest(state, action) {
			state.loading = true;
		},
		debitbuspaymentSuccess(state, action) {
			state.loading = false;
			state.debitPayment = action.payload;
			state.error = null;
		},
		debitbuspaymentFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		mailBusRequest(state, action) {
			state.loading = true;
		},
		mailBusSuccess(state, action) {
			state.loading = false;
			state.mailBus = action.payload;
			state.error = null;
		},
		mailBusFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

const { actions } = BusReducer;
export const {
	cityBusRequest,
	cityBusSuccess,
	cityBusFail,
	addBusSearchRequest,
	addBusSearchSuccess,
	addBusSearchFail,
	busSearchRequest,
	busSearchSuccess,
	busSearchFail,
	seatLayoutRequest,
	seatLayoutSuccess,
	seatLayoutFail,
	addBusBookingRequest,
	addBusBookingSuccess,
	addBusBookingFail,
	busBlockRequest,
	busBlockSuccess,
	busBlockFail,
	busBookingRequest,
	busBookingSuccess,
	busBookingFail,
	busBookingDetailsRequest,
	busBookingDetailsSuccess,
	busBookingDetailsFail,
	debitbuspaymentRequest,
	debitbuspaymentSuccess,
	debitbuspaymentFail,
	mailBusRequest,
	mailBusSuccess,
	mailBusFail,
} = actions;
const config = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': 'NjA4MzFiNDBkZmI1NQ==',
	},
};
export const fetchBusCityDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(cityBusRequest());
		const { data } = await axiosInstance.post(
			'BusSeat/busallsources',
			bodyData,
			config
		);
		console.log(data);
		dispatch(cityBusSuccess(data));
	} catch (error) {
		dispatch(
			cityBusFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addBusSearchDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(addBusSearchRequest());
		const { data } = await axiosInstance.post(
			'Bus/addsearchbus',
			bodyData,
			config
		);

		console.log(data);
		dispatch(addBusSearchSuccess(data));
		if (data.status === 200) {
			dispatch(fetchBusSearchDispatch(bodyData));
		}
	} catch (error) {
		dispatch(
			addBusSearchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const fetchBusSearchDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(busSearchRequest());
		const { data } = await axiosInstance.post(
			'BusSeat/bussearch',
			bodyData,
			config
		);

		console.log(data);
		dispatch(busSearchSuccess(data));
	} catch (error) {
		dispatch(
			busSearchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const fetchSeatLayoutDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(seatLayoutRequest());
		const { data } = await axiosInstance.post(
			'BusSeat/busseatlayout',
			bodyData,
			config
		);

		console.log(data);
		dispatch(seatLayoutSuccess(data));
	} catch (error) {
		dispatch(
			seatLayoutFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addBusBookingDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(addBusBookingRequest());
		const { data } = await axiosInstance.post(
			'bus/addbookingbus',
			bodyData,
			config
		);
		dispatch(addBusBookingSuccess(data));
		if (data.status === 200) {
			if (bodyData.paymentMode === 'OFFLINE') {
				dispatch(setModalPayBusOffline(true));
			} else {
				dispatch(setModalEasebuzzBus(true));
			}
		} else {
			alert(data.result);
			// Navigate()
		}
	} catch (error) {
		dispatch(
			addBusBookingFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const busBlockDispatch = (bodyData, navigate) => async (dispatch) => {
	try {
		dispatch(busBlockRequest());
		const { data } = await axiosInstance.post(
			'BusSeat/busblock',
			bodyData,
			config
		);

		console.log(data);
		dispatch(busBlockSuccess(data));
		if (data.status === 200) {
			let form = {
				orderId: bodyData.orderId,
				paymentId: bodyData.paymentId,
			};
			dispatch(
				debitbuspaymentDispatch(form, data.result.blockKey, navigate, bodyData)
			);
		} else {
		}
	} catch (error) {
		dispatch(
			busBlockFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const debitbuspaymentDispatch =
	(bodyData, blockKey, navigate, dt) => async (dispatch) => {
		try {
			dispatch(debitbuspaymentRequest());
			const { data } = await axiosInstance.post(
				'bus/debitbuspayment',
				bodyData,
				config
			);

			console.log(data);
			dispatch(debitbuspaymentSuccess(data));
			if (data.status === 200) {
				let formdata = {
					orderId: bodyData.orderId,
					blockKey: blockKey,
				};
				dispatch(busBookingDispatch(formdata, navigate, dt));
			} else {
			}
		} catch (error) {
			dispatch(
				debitbuspaymentFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const busBookingDispatch =
	(bodyData, navigate, dt) => async (dispatch) => {
		try {
			dispatch(busBookingRequest());
			const { data } = await axiosInstance.post(
				'BusSeat/busbooking',
				bodyData,
				config
			);

			console.log(data);
			dispatch(busBookingSuccess(data));
			if (data.status === 200) {
				let form = {
					orderId: bodyData.orderId,
					ticketId: data.result,
				};
				dispatch(busBookingDetailsDispatch(form, navigate, dt));
			}
		} catch (error) {
			dispatch(
				busBookingFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const busBookingDetailsDispatch =
	(bodyData, navigate, dt) => async (dispatch) => {
		try {
			dispatch(busBookingDetailsRequest());
			const { data } = await axiosInstance.post(
				'BusSeat/busbookingdetails',
				bodyData,
				config
			);

			console.log(data);
			dispatch(busBookingDetailsSuccess(data));
			if (data.status === 200) {
				dispatch(setModalPayBusOffline(false));
				// alert('Booking Successfull');
				let form = {
					orderId: bodyData.orderId,
					email: dt?.inventoryItems[0]?.passenger?.email,
				};
				dispatch(mailBusDispatch(form));
				navigate('/busticket', { state: { bookingDetail: data } });
			}
		} catch (error) {
			dispatch(
				busBookingDetailsFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const mailBusDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(mailBusRequest());
		const { data } = await axios.post(
			'https://api.marinersforex.com/api/home/mailbusticket',
			bodyData,
			config
		);
		console.log('data', data);
		dispatch(mailBusSuccess(data));
	} catch (error) {
		dispatch(
			mailBusFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export default BusReducer;
