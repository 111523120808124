import React, { useEffect } from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import { Box, Container, Button, Grid } from '@mui/material';
import { Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { walletBalanceAgentDispatch } from '../reducers/AgentReducer';

const FlightBookPage = () => {
	const { bookingDetail } = useSelector((state) => state.tbo);
	const { agentInfo } = useSelector((state) => state.agent);
	const location = useLocation();
	const dispatch = useDispatch();
	console.log('location', location);
	useEffect(() => {
		dispatch(
			walletBalanceAgentDispatch(
				agentInfo?.result?.result?.email,
				agentInfo?.result?.result?.id
			)
		);
	}, []);
	return (
		<>
			<Navbar />
			<Container style={{ marginTop: 100 }}>
				<Box>
					<Box
						display='flex'
						justifyContent={'space-between'}
						alignItems={'center'}>
						<span style={{ fontFamily: 'Outfit' }}>
							Congratulation Your booking is confirmed!
						</span>
						<a
							href={`https://api.marinersforex.com/api/home/downloadticket/${location.state.orderId}`}>
							<Button
								variant='contained'
								color='primary'
								style={{ marginBottom: 10 }}>
								Download
							</Button>
						</a>
					</Box>
					<Divider style={{ border: '2px solid rgba(203, 203, 203, 1)' }} />
					<Box
						style={{
							backgroundColor: 'rgba(237, 240, 233, 1)',
							padding: 20,
							borderRadius: 5,
						}}>
						<Box display={'flex'} justifyContent={'space-between'}>
							<Box>
								Booking Status:{' '}
								<span stye={{ color: 'rgba(14, 77, 141, 1)' }}>CONFIRMED</span>
							</Box>
							<Box>
								Order Date:{' '}
								<span>
									{
										bookingDetail?.result?.Response?.Response?.FlightItinerary
											?.InvoiceCreatedOn
									}
								</span>
							</Box>
						</Box>
						<Box display={'flex'} justifyContent={'space-between'}>
							<Box mt={2}>
								Booking ID: <span>{location.state.orderId}</span>
							</Box>
							<Box mt={2}>
								PNR: {bookingDetail?.result?.Response?.Response?.PNR}
							</Box>
						</Box>
					</Box>
					<Box
						mt={3}
						style={{
							backgroundColor: 'rgba(237, 240, 233, 1)',
							padding: 20,
							borderRadius: 5,
						}}>
						<Box display={'flex'} justifyContent={'space-between'}>
							<Box>Passenger Name</Box>
							<Box>E-Ticket Number</Box>
							<Box>Passenger Type</Box>
						</Box>
						<Divider style={{ border: '1px solid rgba(203, 203, 203, 1)' }} />
						{bookingDetail?.result?.Response?.Response?.FlightItinerary
							?.Passenger?.length > 0 &&
							bookingDetail?.result?.Response?.Response?.FlightItinerary?.Passenger.map(
								(i) => {
									return (
										<Box
											display={'flex'}
											justifyContent={'space-between'}
											mt={2}>
											<Box>{`${i.Title} ${i.FirstName} ${i.LastName}`}</Box>
											<Box>{`${i.Ticket.TicketNumber}(${i.Ticket.TicketId})`}</Box>
											<Box>
												{i.PaxType === 1
													? 'ADULT'
													: i.PaxType === 2
													? 'CHILD'
													: i.PaxType === 3
													? 'INFANT'
													: ''}
											</Box>
										</Box>
									);
								}
							)}
					</Box>
					<Box
						mt={3}
						style={{
							backgroundColor: 'rgba(237, 240, 233, 1)',
							padding: 20,
							borderRadius: 5,
						}}>
						<Divider style={{ border: '1px solid rgba(203, 203, 203, 1)' }} />
						{bookingDetail?.result?.Response?.Response?.FlightItinerary
							?.Segments?.length > 0 &&
							bookingDetail?.result?.Response?.Response?.FlightItinerary?.Segments.map(
								(i) => {
									return (
										<>
											<Box display={'flex'} justifyContent={'space-between'}>
												<Box>
													Flight:{' '}
													{`${i?.Airline?.AirlineName} ${i?.Airline?.AirlineCode},${i?.Airline?.FlightNumber} `}
												</Box>
												<Box>
													Baggage:{' '}
													{`${i?.Baggage}, CabinBaggage ${i?.CabinBaggage}`}
												</Box>
											</Box>
											<Box
												display={'flex'}
												justifyContent={'space-between'}
												mt={2}
												alignItems={'center'}>
												<Box>
													<span>Departure</span>
													<br></br>
													<span>{`${i.Origin.Airport.CityName} (${i.Origin.Airport.AirportCode})`}</span>
													<br></br>
													<span>{i.Origin.DepTime}</span>
												</Box>
												{/* <Box>EDRT45</Box> */}
												<Box>
													<span>Arrival</span>
													<br></br>
													<span>{`${i.Destination.Airport.CityName} (${i.Destination.Airport.AirportCode})`}</span>
													<br></br>
													<span>{i.Destination.ArrTime}</span>
												</Box>
											</Box>
											<Divider
												style={{
													border: '1px dashed rgba(143, 143, 143, 1)',
												}}
											/>
										</>
									);
								}
							)}
					</Box>
					{/* <Box
						mt={3}
						style={{
							backgroundColor: 'rgba(237, 240, 233, 1)',
							padding: 20,
							borderRadius: 5,
						}}>
						<Grid container>
							<Grid item xs={6}>
								<Box>Payment Details</Box>
								<Box alignItems={'center'}>
									<Box mt={2}>Base Fare : ₹ 3000</Box>
									<Box mt={2}>Tax : ₹ 100</Box>
									<Box mt={2}>Total Amount: ₹ 3100 </Box>
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Box>Contact Details</Box>
								<Box alignItems={'center'}>
									<Box mt={2}>Email: manish@gmail.com</Box>
									<Box mt={2}>Mobile No: 9876543245</Box>
								</Box>
							</Grid>
						</Grid>
						<Divider style={{ border: '1px solid rgba(203, 203, 203, 1)' }} />
					</Box> */}
				</Box>
			</Container>
			<Footer />
		</>
	);
};

export default FlightBookPage;
