import React from 'react';
import {
	Box,
	Divider,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
const FilterSectionHotel = ({ setResult }) => {
	const handleStarOne = () => {};
	return (
		<>
			<Box
				style={{
					boxShadow:
						'0px 2.659289598464966px 13.29644775390625px 0px #0000001A',
					padding: 20,
					borderRadius: 15,
				}}>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}>
					<h1 style={{ fontWeight: 'bold', fontSize: 16 }}>Filters</h1>
					<h1 style={{ fontSize: 12 }}>Result All</h1>
				</Box>
				<h1 style={{ fontSize: 14 }}>showing 874 Flights</h1>
				<Divider
					style={{
						border: '1.33px solid #CBCBCB',
						marginTop: 10,
						marginBottom: 10,
					}}
				/>
				<h1 style={{ fontSize: 14 }}>Star Rating</h1>
				<FormGroup style={{ marginTop: 10 }}>
					<FormControlLabel
						control={
							<Checkbox checked={true} name={'1'} onChange={handleStarOne} />
						}
						label={
							<>
								<Box style={{ display: 'flex' }}>
									<span
										style={{
											color: 'rgba(29, 29, 29, 1)',
											marginLeft: 10,
											fontFamily: 'Outfit',
											fontSize: 14,
										}}>
										1 Star
									</span>
								</Box>
							</>
						}
					/>
					<FormControlLabel
						control={<Checkbox checked={true} name={'2'} />}
						label={
							<>
								<Box style={{ display: 'flex' }}>
									<span
										style={{
											color: 'rgba(29, 29, 29, 1)',
											marginLeft: 10,
											fontFamily: 'Outfit',
											fontSize: 14,
										}}>
										2 Star
									</span>
								</Box>
							</>
						}
					/>
					<FormControlLabel
						control={<Checkbox checked={true} name={'3'} />}
						label={
							<>
								<Box style={{ display: 'flex' }}>
									<span
										style={{
											color: 'rgba(29, 29, 29, 1)',
											marginLeft: 10,
											fontFamily: 'Outfit',
											fontSize: 14,
										}}>
										3 Star
									</span>
								</Box>
							</>
						}
					/>
				</FormGroup>
				<Divider
					style={{
						border: '1.33px solid #CBCBCB',
						marginTop: 10,
						marginBottom: 10,
					}}
				/>
				{/* <h1 style={{ fontSize: 14 }}>Property Type</h1>
				<FormGroup style={{ marginTop: 10 }}>
					<FormControlLabel
						control={<Checkbox checked={true} name={'Wifi'} />}
						label={
							<>
								<Box style={{ display: 'flex' }}>
									<span
										style={{
											color: 'rgba(29, 29, 29, 1)',
											marginLeft: 10,
											fontFamily: 'Outfit',
											fontSize: 14,
										}}>
										Hotel
									</span>
								</Box>
							</>
						}
					/>
					<FormControlLabel
						control={<Checkbox checked={true} name={'Wifi'} />}
						label={
							<>
								<Box style={{ display: 'flex' }}>
									<span
										style={{
											color: 'rgba(29, 29, 29, 1)',
											marginLeft: 10,
											fontFamily: 'Outfit',
											fontSize: 14,
										}}>
										Apartment
									</span>
								</Box>
							</>
						}
					/>
					<FormControlLabel
						control={<Checkbox checked={true} name={'Wifi'} />}
						label={
							<>
								<Box style={{ display: 'flex' }}>
									<span
										style={{
											color: 'rgba(29, 29, 29, 1)',
											marginLeft: 10,
											fontFamily: 'Outfit',
											fontSize: 14,
										}}>
										Villa
									</span>
								</Box>
							</>
						}
					/>
				</FormGroup> */}
				<Divider
					style={{
						border: '1.33px solid #CBCBCB',
						marginTop: 10,
						marginBottom: 10,
					}}
				/>
				{/* <h1 style={{ fontSize: 14 }}>Cancellations</h1>
				<FormGroup style={{ marginTop: 10 }}>
					<FormControlLabel
						control={<Checkbox checked={true} name={'Wifi'} />}
						label={
							<>
								<Box style={{ display: 'flex' }}>
									<span
										style={{
											color: 'rgba(29, 29, 29, 1)',
											marginLeft: 10,
											fontFamily: 'Outfit',
											fontSize: 14,
										}}>
										Refundable
									</span>
								</Box>
							</>
						}
					/>
					<FormControlLabel
						control={<Checkbox checked={true} name={'Wifi'} />}
						label={
							<>
								<Box style={{ display: 'flex' }}>
									<span
										style={{
											color: 'rgba(29, 29, 29, 1)',
											marginLeft: 10,
											fontFamily: 'Outfit',
											fontSize: 14,
										}}>
										Non-Refundable
									</span>
								</Box>
							</>
						}
					/>
				</FormGroup> */}
				<Divider
					style={{
						border: '1.33px solid #CBCBCB',
						marginTop: 10,
						marginBottom: 10,
					}}
				/>
				{/* <h1 style={{ fontSize: 14 }}>Star Categories</h1>
				<FormGroup style={{ marginTop: 10 }}>
					<FormControlLabel
						control={<Checkbox checked={true} name={'Wifi'} />}
						label={
							<>
								<Box style={{ display: 'flex' }}>
									<span
										style={{
											color: 'rgba(29, 29, 29, 1)',
											marginLeft: 10,
											fontFamily: 'Outfit',
											fontSize: 14,
										}}>
										Wi-Fi
									</span>
								</Box>
							</>
						}
					/>
					<FormControlLabel
						control={<Checkbox checked={true} name={'Wifi'} />}
						label={
							<>
								<Box style={{ display: 'flex' }}>
									<span
										style={{
											color: 'rgba(29, 29, 29, 1)',
											marginLeft: 10,
											fontFamily: 'Outfit',
											fontSize: 14,
										}}>
										Wi-Fi
									</span>
								</Box>
							</>
						}
					/>
					<FormControlLabel
						control={<Checkbox checked={true} name={'Wifi'} />}
						label={
							<>
								<Box style={{ display: 'flex' }}>
									<span
										style={{
											color: 'rgba(29, 29, 29, 1)',
											marginLeft: 10,
											fontFamily: 'Outfit',
											fontSize: 14,
										}}>
										Wi-Fi
									</span>
								</Box>
							</>
						}
					/>
				</FormGroup> */}
			</Box>
		</>
	);
};

export default FilterSectionHotel;
