import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Box, Grid, Button } from '@mui/material';
import { Divider } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const title = ['', 'Mr', 'Mrs', 'Ms', 'Mstr', 'Miss'];

const BusSummaryForm = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [value, setValue] = useState(0);
	const { depart, arrival } = useSelector((state) => state.ui);
	const handleFormSubmit = (data) => {
		console.log('Data', data);
		navigate('/busconfirm', {
			state: {
				seat: location.state.seat,
				formData: location.state.formData,
				busDetail: location.state.busDetail,
				boardingPoint: location.state.boardingPoint,
				dropingPoint: location.state.dropingPoint,
				passengerData: data,
				orderId: location.state.orderId,
			},
		});
	};
	let arr = [];
	for (let i = 1; i <= parseInt(location.state.seat?.length); i++) {
		arr.push({
			title: '',
			firstname: '',
			lastname: '',
			age: '',
			gender: '',
		});
	}

	return (
		<>
			<Formik
				initialValues={{
					email: '',
					phone: '',
					adult: arr,
				}}
				validationSchema={Yup.object({
					email: Yup.string().email('Invalid email').required('Email Required'),
					phone: Yup.string().required('Phone required').max(10),
					adult: Yup.array().of(
						Yup.object().shape({
							firstname: Yup.string().required('firstname required'),
							lastname: Yup.string().required('lastname required'),
							title: Yup.string().required('title required'),
							gender: Yup.string().required('gender required'),
							age: Yup.string().required('age required'),
						})
					),
				})}
				onSubmit={handleFormSubmit}>
				{(formik, values) => {
					const { errors, setFieldValue } = formik;
					console.log(
						'first',
						Object?.keys(errors)?.length === 0 && errors?.constructor === Object
					);
					console.log('values', formik?.values);
					console.log('errors', errors);
					return (
						<Form>
							<Box
								mt={2}
								style={{
									boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
								}}>
								<div
									style={{
										background: 'rgba(237, 240, 233, 1)',
										padding: 12,
										borderTopLeftRadius: 5,
										borderTopRightRadius: 5,
									}}>
									Contact Information
								</div>
								<Grid
									container
									style={{ marginTop: 5, padding: 10 }}
									spacing={4}>
									<Grid item xs={12} lg={6}>
										<Field
											name='email'
											type='text'
											placeHolder='Email'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.email
													? '2px solid red'
													: '1.33px solid rgba(203, 203, 203, 1)',
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.email ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.email}
											</span>
										) : null}
									</Grid>
									<Grid item xs={12} lg={6}>
										<Field
											name='phone'
											type='text'
											placeHolder='Phone'
											style={{
												padding: 10,
												width: '100%',
												borderRadius: 5,
												border: errors?.phone
													? '2px solid red'
													: '1.33px solid rgba(203, 203, 203, 1)',
											}}
											inputProps={{
												style: { padding: 12 },
											}}
										/>
										<br />
										{errors?.phone ? (
											<span style={{ color: 'red', fontSize: 12 }}>
												{errors?.phone}
											</span>
										) : null}
									</Grid>
								</Grid>
							</Box>

							<div
								style={{
									background: 'rgba(237, 240, 233, 1)',
									padding: 12,
									borderTopLeftRadius: 5,
									borderTopRightRadius: 5,
									marginTop: 20,
								}}>
								Traveller Details
							</div>

							<Box
								style={{
									boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
									padding: 20,
								}}>
								<FieldArray
									name='adult'
									render={(arrayHelpers) => {
										console.log('arrayHelpers', arrayHelpers);
										return (
											<div>
												{formik?.values?.adult?.map(
													(adults, index) =>
														location.state.seat &&
														location.state.seat.length > 0 &&
														location.state.seat.map((i, ind) => {
															return index === ind ? (
																<>
																	<Box
																		style={{
																			marginTop: 20,
																			border:
																				'1.33px solid rgba(203, 203, 203, 1)',
																			padding: 20,
																			borderRadius: 5,
																		}}>
																		<Box
																			style={{
																				display: 'flex',
																				justifyContent: 'space-between',
																				alignItems: 'center',
																			}}>
																			<Box
																				style={{ display: 'flex' }}
																				alignItems={'center'}>
																				{Object?.keys(errors)?.length === 0 &&
																				errors?.constructor === Object ? (
																					<svg
																						focusable='false'
																						color='inherit'
																						fill='none'
																						aria-hidden='true'
																						role='presentation'
																						viewBox='0 0 20 21'
																						preserveAspectRatio='xMidYMid meet'
																						width='24px'
																						height='24px'
																						class='sc-hMqMXs llihTZ'>
																						<path
																							d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																							fill='#319E37'></path>
																					</svg>
																				) : errors?.adult ? (
																					errors?.adult?.length > 0 ? (
																						errors?.adult[index] ===
																						undefined ? (
																							<svg
																								focusable='false'
																								color='inherit'
																								fill='none'
																								aria-hidden='true'
																								role='presentation'
																								viewBox='0 0 20 21'
																								preserveAspectRatio='xMidYMid meet'
																								width='24px'
																								height='24px'
																								class='sc-hMqMXs llihTZ'>
																								<path
																									d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																									fill='#319E37'></path>
																							</svg>
																						) : (
																							<svg
																								focusable='false'
																								color='grey'
																								fill='rgb(216, 220, 222)'
																								aria-hidden='true'
																								role='presentation'
																								viewBox='0 0 20 21'
																								preserveAspectRatio='xMidYMid meet'
																								width='24px'
																								height='24px'
																								class='sc-hMqMXs llihTZ'>
																								<path
																									d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																									fill='rgb(216, 220, 222)'></path>
																							</svg>
																						)
																					) : (
																						<svg
																							focusable='false'
																							color='inherit'
																							fill='none'
																							aria-hidden='true'
																							role='presentation'
																							viewBox='0 0 20 21'
																							preserveAspectRatio='xMidYMid meet'
																							width='24px'
																							height='24px'
																							class='sc-hMqMXs llihTZ'>
																							<path
																								d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																								fill='#319E37'></path>
																						</svg>
																					)
																				) : (
																					<svg
																						focusable='false'
																						color='inherit'
																						fill='none'
																						aria-hidden='true'
																						role='presentation'
																						viewBox='0 0 20 21'
																						preserveAspectRatio='xMidYMid meet'
																						width='24px'
																						height='24px'
																						class='sc-hMqMXs llihTZ'>
																						<path
																							d='M10 0.75C8.07164 0.75 6.18657 1.32183 4.58319 2.39317C2.97982 3.46452 1.73013 4.98726 0.992179 6.76884C0.254224 8.55042 0.061142 10.5108 0.437348 12.4021C0.813554 14.2934 1.74215 16.0307 3.10571 17.3943C4.46927 18.7579 6.20656 19.6865 8.09787 20.0627C9.98919 20.4389 11.9496 20.2458 13.7312 19.5078C15.5127 18.7699 17.0355 17.5202 18.1068 15.9168C19.1782 14.3134 19.75 12.4284 19.75 10.5C19.747 7.91506 18.7188 5.43684 16.891 3.60901C15.0632 1.78118 12.5849 0.752988 10 0.75ZM14.6436 8.79272L9.14307 14.0427C9.00322 14.1758 8.81756 14.25 8.62452 14.25C8.43147 14.25 8.24582 14.1758 8.10596 14.0427L5.35645 11.4177C5.2128 11.2801 5.12965 11.0911 5.12524 10.8923C5.12084 10.6934 5.19555 10.5009 5.33297 10.3571C5.47038 10.2133 5.65927 10.1299 5.85813 10.1252C6.05699 10.1206 6.24956 10.195 6.39356 10.3323L8.62452 12.4629L13.6064 7.70728C13.7504 7.57004 13.943 7.49558 14.1419 7.50023C14.3407 7.50489 14.5296 7.58828 14.667 7.7321C14.8045 7.87592 14.8792 8.06841 14.8748 8.26727C14.8704 8.46614 14.7872 8.65513 14.6436 8.79272Z'
																							fill='#319E37'></path>
																					</svg>
																				)}

																				<div style={{ marginLeft: 5 }}>
																					{formik?.values?.adult[index].title &&
																					formik?.values?.adult[index]
																						.firstname &&
																					formik?.values?.adult[index].lastname
																						? `${formik?.values?.adult[index].title} ${formik?.values?.adult[index].firstname} ${formik?.values?.adult[index].lastname}`
																						: `Adult ${index + 1}`}
																				</div>
																				<div>{` ,Seat No. ${i.name}`}</div>
																			</Box>
																			<svg
																				style={{ cursor: 'pointer' }}
																				onClick={() =>
																					value === index
																						? setValue(-1)
																						: setValue(index)
																				}
																				focusable='false'
																				color='secondaryDark'
																				fill='currentcolor'
																				aria-hidden='true'
																				role='presentation'
																				viewBox='0 0 150 150'
																				preserveAspectRatio='xMidYMid meet'
																				size='9'
																				width='9'
																				height='9'
																				class='sc-hMqMXs gDtCsn'>
																				<path d='M86.8 118.6l60.1-61.5c5.5-5.7 5.5-14.8 0-20.5l-5-5.1c-5.4-5.5-14.3-5.6-19.8-.2l-.2.2L76 78.4 30.1 31.5c-5.4-5.5-14.3-5.6-19.8-.2l-.2.2-5 5.1c-5.5 5.7-5.5 14.8 0 20.5l60.1 61.5c2.8 2.9 6.8 4.4 10.8 4.1 4 .3 8-1.3 10.8-4.1z'></path>
																			</svg>
																		</Box>

																		{index === value ? (
																			<>
																				<Box
																					display={'flex'}
																					mt={2}
																					ml={1.5}
																					mb={2}>
																					<Box
																						onClick={() => {
																							setFieldValue(
																								`adult.${index}.gender`,
																								'Male'
																							);
																							console.log(
																								'formikvalues',
																								formik?.values.adult[index]
																									.gender
																							);
																						}}
																						style={{
																							padding: '10px 30px',
																							borderTopLeftRadius: 5,
																							borderBottomLeftRadius: 5,
																							background:
																								formik?.values.adult[index]
																									.gender === 'Male'
																									? 'rgb(77, 165, 40)'
																									: '',
																							color:
																								formik?.values.adult[index]
																									.gender === 'Male'
																									? 'white'
																									: '',
																							cursor: 'pointer',
																							border:
																								Object?.keys(errors)?.length ===
																									0 &&
																								errors?.constructor === Object
																									? '1.33px solid rgba(203, 203, 203, 1) '
																									: errors?.adult
																									? errors?.adult[index]?.gender
																										? '2px solid red'
																										: '1.33px solid rgba(203, 203, 203, 1) '
																									: '1.33px solid rgba(203, 203, 203, 1)',
																						}}>
																						MALE
																					</Box>
																					<Box
																						onClick={() => {
																							setFieldValue(
																								`adult.${index}.gender`,
																								'Female'
																							);
																							console.log(
																								'formikvalues',
																								formik?.values.adult[index]
																									.gender
																							);
																						}}
																						style={{
																							// border:
																							// 	'1.33px solid rgba(203, 203, 203, 1)',
																							padding: '10px 30px',
																							borderTopRightRadius: 5,
																							borderBottomRightRadius: 5,
																							background:
																								formik?.values.adult[index]
																									.gender === 'Female'
																									? 'rgb(77, 165, 40)'
																									: '',
																							color:
																								formik?.values.adult[index]
																									.gender === 'Female'
																									? 'white'
																									: '',
																							cursor: 'pointer',
																							border:
																								Object?.keys(errors)?.length ===
																									0 &&
																								errors?.constructor === Object
																									? '1.33px solid rgba(203, 203, 203, 1) '
																									: errors?.adult
																									? errors?.adult[index]?.gender
																										? '2px solid red'
																										: '1.33px solid rgba(203, 203, 203, 1) '
																									: '1.33px solid rgba(203, 203, 203, 1)',
																						}}>
																						FEMALE
																					</Box>
																				</Box>
																				<Grid
																					container
																					style={{ padding: 10 }}
																					spacing={4}>
																					<Grid item xs={12} lg={6}>
																						<Field
																							name={`adult.${index}.title`}
																							as='select'
																							style={{
																								padding: 10,
																								width: '100%',
																								borderRadius: 5,
																								border:
																									Object?.keys(errors)
																										?.length === 0 &&
																									errors?.constructor === Object
																										? '1.33px solid rgba(203, 203, 203, 1)'
																										: errors?.adult
																										? errors?.adult[index]
																												?.title
																											? '2px solid red'
																											: '1.33px solid rgba(203, 203, 203, 1)'
																										: '1.33px solid rgba(203, 203, 203, 1)',
																							}}
																							inputProps={{
																								style: { padding: 12 },
																							}}>
																							{title.map((item, index) => (
																								<option value={item}>
																									{item}
																								</option>
																							))}
																						</Field>
																						{Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object ? (
																							''
																						) : errors?.adult ? (
																							errors?.adult[index]?.title ? (
																								<span
																									style={{
																										color: 'red',
																										fontSize: 12,
																									}}>
																									{errors?.adult[index]?.title}
																								</span>
																							) : null
																						) : (
																							''
																						)}
																					</Grid>
																					<Grid item xs={12} lg={6}>
																						<Field
																							name={`adult.${index}.firstname`}
																							type='text'
																							placeHolder='First Name'
																							style={{
																								padding: 10,
																								width: '100%',
																								borderRadius: 5,
																								border:
																									Object?.keys(errors)
																										?.length === 0 &&
																									errors?.constructor === Object
																										? '1.33px solid rgba(203, 203, 203, 1) '
																										: errors?.adult
																										? errors?.adult[index]
																												?.firstname
																											? '2px solid red'
																											: '1.33px solid rgba(203, 203, 203, 1) '
																										: '1.33px solid rgba(203, 203, 203, 1)',
																							}}
																							inputProps={{
																								style: { padding: 12 },
																							}}
																						/>
																						<br />
																						{Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object ? (
																							''
																						) : errors?.adult ? (
																							errors?.adult[index]
																								?.firstname ? (
																								<span
																									style={{
																										color: 'red',
																										fontSize: 12,
																									}}>
																									{
																										errors?.adult[index]
																											?.firstname
																									}
																								</span>
																							) : null
																						) : (
																							''
																						)}
																					</Grid>
																					<Grid item xs={12} lg={6}>
																						<Field
																							name={`adult.${index}.lastname`}
																							type='text'
																							placeHolder='Last Name'
																							style={{
																								padding: 10,
																								width: '100%',
																								borderRadius: 5,
																								border:
																									Object?.keys(errors)
																										?.length === 0 &&
																									errors?.constructor === Object
																										? '1.33px solid rgba(203, 203, 203, 1) '
																										: errors?.adult
																										? errors?.adult[index]
																												?.lastname
																											? '2px solid red'
																											: '1.33px solid rgba(203, 203, 203, 1) '
																										: '1.33px solid rgba(203, 203, 203, 1)',
																							}}
																							inputProps={{
																								style: { padding: 12 },
																							}}
																						/>
																						<br />
																						{Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object ? (
																							''
																						) : errors?.adult ? (
																							errors?.adult[index]?.lastname ? (
																								<span
																									style={{
																										color: 'red',
																										fontSize: 12,
																									}}>
																									{
																										errors?.adult[index]
																											?.lastname
																									}
																								</span>
																							) : null
																						) : (
																							''
																						)}
																					</Grid>
																					<Grid item xs={12} lg={6}>
																						<Field
																							name={`adult.${index}.age`}
																							type='number'
																							placeHolder='Age'
																							style={{
																								padding: 10,
																								width: '100%',
																								borderRadius: 5,
																								border:
																									Object?.keys(errors)
																										?.length === 0 &&
																									errors?.constructor === Object
																										? '1.33px solid rgba(203, 203, 203, 1) '
																										: errors?.adult
																										? errors?.adult[index]?.age
																											? '2px solid red'
																											: '1.33px solid rgba(203, 203, 203, 1) '
																										: '1.33px solid rgba(203, 203, 203, 1)',
																							}}
																							inputProps={{
																								style: { padding: 12 },
																							}}
																						/>
																						<br />
																						{Object?.keys(errors)?.length ===
																							0 &&
																						errors?.constructor === Object ? (
																							''
																						) : errors?.adult ? (
																							errors?.adult[index]?.age ? (
																								<span
																									style={{
																										color: 'red',
																										fontSize: 12,
																									}}>
																									{errors?.adult[index]?.age}
																								</span>
																							) : null
																						) : (
																							''
																						)}
																					</Grid>
																				</Grid>
																			</>
																		) : (
																			''
																		)}
																	</Box>
																</>
															) : (
																''
															);
														})
												)}
											</div>
										);
									}}
								/>
								<Divider />
							</Box>
							<Grid container>
								<Grid item xs={3}></Grid>
								<Grid item xs={12} lg={6}>
									<Button
										type='submit'
										style={{
											background: 'rgba(77, 165, 40, 1)',
											color: 'white',
											width: '100%',
											marginTop: 30,
											padding: 10,
											borderRadius: 12,
										}}>
										Continue
									</Button>
								</Grid>
								<Grid item xs={3}></Grid>
							</Grid>
						</Form>
					);
				}}
			</Formik>
		</>
	);
};

export default BusSummaryForm;
