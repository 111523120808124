import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const DynamicPage = ({ title, description }) => {
	return (
		<>
			<>
				<Helmet>
					<title>{title}</title>
					<meta name='description' content={description} />
				</Helmet>
				{/* <div>
					<h1>Welcome to {title}</h1>
					<p>{description}</p>
				</div> */}
			</>
		</>
	);
};

export default DynamicPage;
