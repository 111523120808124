import React, { useEffect, useState } from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import {
	Grid,
	Box,
	Button,
	Container,
	useTheme,
	useMediaQuery,
	TextField,
} from '@mui/material';
import loc from '../assets/images/loctions.svg';
import dayjs from 'dayjs';
import calender from '../assets/images/calender.svg';
import locwhite from '../assets/images/locwhite.svg';
import calwhite from '../assets/images/calwhite.svg';
import travwhite from '../assets/images/travwhite.svg';
import Popover from '@mui/material/Popover';
import flightlogo from '../assets/images/flightlogo.svg';
import Guest from '../assets/images/Guest.svg';
import search from '../assets/images/search.svg';
import tick from '../assets/images/tick.svg';
import { DatePicker } from 'antd';
import FilterSectionHotel from '../components/resuableComponents/FilterSectionHotel';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import Autocomplete from '@mui/material/Autocomplete';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
	addhotelSearchDispatch,
	fetchHotelCityDispatch,
} from '../reducers/HotelReducer';
import {
	removeSelectedRoom,
	setAdultH,
	setChildAge1,
	setChildAge2,
	setChildAge3,
	setChildAge4,
	setChildH,
	setSelectedHotel,
	setSelectedRoom,
} from '../reducers/UiReducer';
import FlightRowSkeleton from '../components/resuableComponents/FlightRowSkeleton';

const dateFormat = 'YYYY-MM-DD';

const HotelResultPage = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const navigate = useNavigate();
	const location = useLocation();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [departDate, setDepartDate] = useState('');
	const [result, setResult] = useState([]);
	const [returnDate, setDepartDate2] = useState('');
	const { hotelResult, loadinghotel, hotelCity } = useSelector(
		(state) => state.hoteltbo
	);
	const {
		depart,
		arrival,
		hoteldes,
		adultH,
		room,
		childH,
		childAge1,
		childAge2,
		childAge3,
		childAge4,
	} = useSelector((state) => state.ui);
	const [num, setNum] = useState(1);
	const [adult, setAdult] = useState(1);
	const [child, setChild] = useState(0);
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	const dispatch = useDispatch();
	console.log('hotelResult', hotelResult);
	const onChange = (date, dateString) => {
		setDepartDate(dateString);
	};
	const onChangeReturn = (date, dateString) => {
		setDepartDate2(dateString);
	};
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleChange = (event) => {
		let value = event.target.value.toLowerCase();
		let cityData = {
			keywords: value,
			limits: 10,
		};
		dispatch(fetchHotelCityDispatch(cityData));
	};
	function disabledDate(current) {
		return moment().add(-1, 'days') >= current;
	}
	useEffect(() => {
		window.scrollTo(0, 0);
		if (location.state) {
			dispatch(addhotelSearchDispatch(location.state));
		}
	}, [location.state]);
	console.log('Location', location);
	var date1 = new Date(departDate ? departDate : location.state.checkInDate);
	var date2 = new Date(returnDate ? returnDate : location.state.checkOutDate);
	var timeDiff = Math.abs(date2.getTime() - date1.getTime());
	var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
	console.log('numberofnights', numberOfNights);
	const handleTBO = () => {
		let formData = {
			checkInDate: departDate ? departDate : location.state.checkInDate,
			checkOutDate: returnDate ? returnDate : location.state.checkOutDate,
			noOfNights: numberOfNights,
			countryName: hoteldes.country_name,
			countryCode: hoteldes.country_code,
			cityName: hoteldes.city_name,
			cityId: hoteldes.city_id,
			noOfRooms: room.length,
			guestNationality: 'IN',
			adultPax: adultH,
			childPax: childH,
			childAge: [...childAge1, ...childAge2, ...childAge3, ...childAge4],
			preferredCurrencyCode: 'INR',
			hotelCodeList: '0',
			starMaxRating: 5,
			startMinRating: 0,
		};
		// $1$pLx7Cf0C$JH0bormSXf/acY34KhBce1
		navigate('/hotelresult', { state: formData });
	};
	console.log('result', result);
	return (
		<>
			<Navbar />
			<MNavbar />
			{!matches ? (
				<Box style={{ backgroundColor: 'rgba(239, 241, 238, 1)', padding: 20 }}>
					<Container>
						<Grid container spacing={2}>
							<Grid item xs={3}>
								<Box
									display={'flex'}
									alignItems={'center'}
									style={{
										backgroundColor: 'rgba(218, 226, 214, 1)',
										padding: '10px 20px',
										borderRadius: 5,
									}}>
									<img
										src={locwhite}
										style={{ height: 25, width: 25, objectFit: 'contain' }}
									/>
									<Box style={{ width: '100%' }}>
										<span
											style={{
												color: 'black',
												marginLeft: 10,
												fontSize: 14,
											}}>
											Location
										</span>
										<Autocomplete
											required
											id='country-select-demo'
											sx={{
												width: '100%',
												marginLeft: 2,
												marginTop: 1,
												// '& .MuiFormLabel-root': {
												// 	color: 'white',
												// },
												// '& .MuiInputBase-input': {
												// 	color: 'rgba(207, 201, 201, 1)',
												// },
												// '& .MuiSvgIcon-root': {
												// 	color: 'white',
												// },
											}}
											options={
												hotelCity && hotelCity.result.length > 0
													? hotelCity.result
													: []
											}
											autoHighlight
											ListboxProps={{
												className: 'myCustomList',
											}}
											value={hoteldes}
											getOptionLabel={(option) =>
												`${option.city_name} (${option.country_name})`
											}
											renderOption={(props, option) => (
												<Box
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center',
													}}
													component='li'
													sx={{
														'& > img': {
															mr: 2,
															flexShrink: 0,
															fontSize: 14,
														},
													}}
													{...props}>
													<Box>
														<Box display={'flex'}>
															<img
																src={flightlogo}
																style={{ width: 20, height: 20 }}
															/>
															<p style={{ fontSize: 13, marginLeft: 5 }}>
																{option.city_name} - {option.country_code}
															</p>
														</Box>
														<p
															style={{
																fontSize: 13,
																marginLeft: 25,
																fontFamily: 'Outfit',
																textOverflow: 'ellipsis',
																overflow: 'hidden',
																width: '200px',
																whiteSpace: 'nowrap',
															}}>
															{option.city_name}
														</p>
													</Box>
													<img
														loading='lazy'
														width='25'
														height='25'
														src={`https://flagcdn.com/w20/${option.country_code.toLowerCase()}.png`}
														srcSet={`https://flagcdn.com/w40/${option.country_code.toLowerCase()}.png 2x`}
														alt=''
													/>
												</Box>
											)}
											onChange={(event, value) => {
												dispatch(setSelectedHotel(value));
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													id='standard-basic'
													// label='To'
													variant='standard'
													onChange={handleChange}
													style={{ fontSize: 12 }}
													inputProps={{
														...params.inputProps,
														autoComplete: 'new-password', // disable autocomplete and autofill
													}}
												/>
											)}
										/>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={2}>
								<Box
									display={'flex'}
									alignItems={'center'}
									style={{
										width: '100%',
										backgroundColor: 'rgba(218, 226, 214, 1)',
										padding: '10px 20px',
										borderRadius: 5,
									}}>
									<img
										src={calwhite}
										style={{
											height: 25,
											width: 25,
											objectFit: 'contain',
											marginTop: 5,
										}}
									/>
									<Box className='hoteldatepicker'>
										<span
											style={{
												// color: 'white',
												marginLeft: 10,
												fontSize: 14,
											}}>
											Check In
										</span>
										<DatePicker
											disabledDate={disabledDate}
											onChange={onChange}
											placeholder=''
											defaultValue={dayjs(
												location.state.checkInDate,
												dateFormat
											)}
											format={dateFormat}
											style={{
												width: '100%',
												padding: 8,
												border: 'none',
												borderBottom: '1px solid',
												borderRadius: '1px',
												boxShadow: 'none',
												background: 'transparent',
											}}
										/>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={2}>
								<Box
									className='hoteldatepicker'
									display={'flex'}
									alignItems={'center'}
									style={{
										width: '100%',
										backgroundColor: 'rgba(218, 226, 214, 1)',
										padding: '10px 20px',
										borderRadius: 5,
									}}>
									<img
										src={calwhite}
										style={{
											height: 25,
											width: 25,
											objectFit: 'contain',
											marginTop: 5,
										}}
									/>
									<Box>
										<span
											style={{
												// color: 'white',
												marginLeft: 10,
												fontSize: 14,
											}}>
											Check Out
										</span>
										<DatePicker
											disabledDate={disabledDate}
											onChange={onChangeReturn}
											placeholder=''
											defaultValue={dayjs(
												location.state.checkOutDate,
												dateFormat
											)}
											format={dateFormat}
											style={{
												width: '100%',
												padding: 8,
												border: 'none',
												borderBottom: '1px solid',
												borderRadius: '1px',
												boxShadow: 'none',
												background: 'transparent',
											}}
										/>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={3}>
								<Box
									display={'flex'}
									style={{
										backgroundColor: 'rgba(218, 226, 214, 1)',
										padding: '10px 20px',
										borderRadius: 5,
										width: '100%',
										height: '100%',
										alignItems: 'center',
									}}>
									<img
										src={travwhite}
										style={{
											height: 25,
											width: 25,
											objectFit: 'contain',
											marginTop: 5,
										}}
									/>
									<Box>
										<span
											style={{
												// color: 'white',
												marginLeft: 10,
												fontSize: 14,
											}}>
											Guest
										</span>
										<br></br>
										<Button
											style={{
												backgroud: 'transparent',
												// color: 'white',
												textTransform: 'capitalize',
											}}
											aria-describedby={id}
											variant='text'
											onClick={handleClick}>
											{adult} Adult {child} Child
										</Button>
										<Popover
											id={id}
											open={open}
											style={{ marginTop: 5, padding: 10 }}
											anchorEl={anchorEl}
											onClose={handleClose}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'left',
											}}>
											<ul style={{ padding: 10 }}>
												{room.map((i, index) => {
													return (
														<li>
															<Box style={{ padding: 5 }}>
																<span
																	style={{
																		color: 'black',
																		fontSize: 14,
																		fontWeight: 500,
																		fontFamily: 'Poppins',
																	}}>
																	Room {index + 1}
																</span>
																<Box
																	display='flex'
																	justifyContent='space-between'>
																	<Box>
																		<span
																			style={{
																				color: 'black',
																				fontSize: 14,
																				fontWeight: 500,
																				fontFamily: 'Poppins',
																			}}>
																			Adult
																		</span>
																		<span
																			style={{
																				fontSize: 10,
																				fontWeight: 'grey',
																				marginLeft: 5,
																			}}>
																			Above 12 years
																		</span>
																		<Box display='flex' alignItems='center'>
																			<Box
																				style={{
																					cursor: 'pointer',
																					background: 'rgb(77, 165, 40)',
																					padding: '0px 10px',
																					// borderRadius: '5px',
																				}}
																				onClick={() => {
																					dispatch(
																						setAdultH(
																							`${adultH[index] - 1}A${index}`
																						)
																					);
																				}}>
																				<span
																					style={{
																						fontSize: 16,
																						color: 'white',
																					}}>
																					-
																				</span>
																			</Box>
																			<Box
																				style={{
																					cursor: 'pointer',
																					background: 'lightgrey',
																					padding: '0px 10px',
																				}}>
																				<span
																					style={{
																						fontSize: 16,
																						color: 'black',
																					}}>
																					{adultH[index] ? adultH[index] : 0}
																				</span>
																			</Box>
																			<Box
																				style={{
																					cursor: 'pointer',
																					background: 'rgb(77, 165, 40)',
																					padding: '0px 10px',
																				}}
																				onClick={() => {
																					dispatch(
																						setAdultH(
																							`${
																								adultH[index]
																									? adultH[index] + 1
																									: 0 + 1
																							}A${index}`
																						)
																					);
																				}}>
																				<span
																					style={{
																						fontSize: 16,
																						color: 'white',
																					}}>
																					+
																				</span>
																			</Box>
																		</Box>
																	</Box>
																	<Box ml={4}>
																		<span
																			style={{
																				color: 'black',
																				fontSize: 14,
																				fontWeight: 500,
																				fontFamily: 'Poppins',
																			}}>
																			Child
																		</span>
																		<span
																			style={{
																				fontSize: 10,
																				fontWeight: 'grey',
																				marginLeft: 5,
																			}}>
																			Below 12 years
																		</span>
																		<Box display='flex' alignItems='center'>
																			<Box
																				style={{
																					cursor: 'pointer',
																					background: 'rgb(77, 165, 40)',
																					padding: '0px 10px',
																				}}
																				onClick={() => {
																					dispatch(
																						setChildH(
																							`${childH[index] - 1}C${index}`
																						)
																					);
																				}}>
																				<span
																					style={{
																						fontSize: 16,
																						color: 'white',
																					}}>
																					-
																				</span>
																			</Box>
																			<Box
																				style={{
																					cursor: 'pointer',
																					background: 'lightgrey',
																					padding: '0px 10px',
																				}}>
																				<span
																					style={{
																						fontSize: 16,
																						color: 'black',
																					}}>
																					{childH[index] ? childH[index] : 0}
																				</span>
																			</Box>
																			<Box
																				style={{
																					cursor: 'pointer',
																					background: 'rgb(77, 165, 40)',
																					padding: '0px 10px',
																				}}
																				onClick={() => {
																					dispatch(
																						setChildH(
																							`${
																								childH[index]
																									? childH[index] + 1
																									: 0 + 1
																							}C${index}`
																						)
																					);
																				}}>
																				<span
																					style={{
																						fontSize: 16,
																						color: 'white',
																					}}>
																					+
																				</span>
																			</Box>
																		</Box>
																	</Box>
																</Box>
															</Box>
															<Box
																display='flex'
																style={{
																	paddingRight: '27px',
																	marginTop: 10,
																}}>
																{Array.from(Array(childH[index])).map(
																	(i, ind) => {
																		return (
																			<Box ml={ind === 0 ? '' : '5px'}>
																				<label for='cars'>Age:</label>
																				<select
																					onChange={(e) =>
																						dispatch(
																							index === 0
																								? setChildAge1(
																										`${e.target.value}C${ind}`
																								  )
																								: index === 1
																								? setChildAge2(
																										`${e.target.value}C${ind}`
																								  )
																								: index === 2
																								? setChildAge3(
																										`${e.target.value}C${ind}`
																								  )
																								: index === 3
																								? setChildAge4(
																										`${e.target.value}C${ind}`
																								  )
																								: ''
																						)
																					}
																					name='cars'
																					id='cars'
																					style={{
																						border: '1px solid grey',
																						paddingLeft: 10,
																						paddingRight: 10,
																						marginLeft: 5,
																					}}>
																					<option value='1'>1</option>
																					<option value='2'>2</option>
																					<option value='3'>3</option>
																					<option value='4'>4</option>
																					<option value='5'>5</option>
																					<option value='6'>6</option>
																					<option value='7'>7</option>
																					<option value='8'>8</option>
																					<option value='9'>9</option>
																					<option value='10'>10</option>
																					<option value='11'>11</option>
																					<option value='12'>12</option>
																				</select>
																			</Box>
																		);
																	}
																)}
															</Box>
														</li>
													);
												})}
												<li>
													<Box
														display='flex'
														justifyContent='space-between'
														style={{ marginTop: 20 }}>
														<Box
															onClick={() => dispatch(setSelectedRoom(1))}
															style={{
																border: '1px solid grey',
																paddingLeft: '5px',
																paddingRight: '5px',
																cursor: 'pointer',
																borderRadius: 5,
															}}>
															<span style={{ fontSize: 12 }}>Add Room</span>
														</Box>
														<Box
															onClick={() => dispatch(removeSelectedRoom(1))}
															style={{
																border: '1px solid #ea2330',
																paddingLeft: '5px',
																paddingRight: '5px',
																cursor: 'pointer',
																background: '#ea2330',
																color: 'white',
																borderRadius: 5,
															}}>
															<span style={{ fontSize: 12 }}>Remove Room</span>
														</Box>
														{/* <Box
																style={{
																	border: '1px solid grey',
																	paddingLeft: '5px',
																	paddingRight: '5px',
																	cursor: 'pointer',
																	background: 'rgb(0, 55, 114)',
																	color: 'white',
																}}>
																<span style={{ fontSize: 12 }}>Done</span>
															</Box> */}
													</Box>
												</li>
											</ul>
											{/* <Box
													display={'flex'}
													justifyContent={'space-between'}
													alignItems={'center'}>
													<Typography sx={{ p: 2, fontSize: 12 }}>
														ADULTS (12y +)<br></br>
														<span style={{ fontSize: 10 }}>
															on the day of travel
														</span>
													</Typography>
													<Box display={'flex'} alignItems={'center'}>
														<Box
															onClick={() => setAdult(adult - 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginLeft: 10,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															-
														</Box>
														<Box
															style={{
																backgroud: 'green',
																marginLeft: 10,
																marginRight: 10,
															}}>
															{adult}
														</Box>
														<Box
															onClick={() => setAdult(adult + 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginRight: 10,
																marginLeft: 5,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															+
														</Box>
													</Box>
												</Box>
												<Box
													display={'flex'}
													justifyContent={'space-between'}
													alignItems={'center'}>
													<Typography sx={{ p: 2, fontSize: 12 }}>
														CHILDS (2y-12y)<br></br>
														<span style={{ fontSize: 10 }}>
															on the day of travel
														</span>
													</Typography>
													<Box display={'flex'} alignItems={'center'}>
														<Box
															onClick={() => setChild(child - 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginLeft: 10,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															-
														</Box>
														<Box
															style={{
																backgroud: 'green',
																marginLeft: 10,
																marginRight: 10,
															}}>
															{child}
														</Box>
														<Box
															onClick={() => setChild(child + 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginRight: 10,
																marginLeft: 5,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															+
														</Box>
													</Box>
												</Box>

												<Box
													display={'flex'}
													justifyContent={'space-between'}
													alignItems={'center'}>
													<Typography sx={{ p: 2, fontSize: 12 }}>
														No. of Nights<br></br>
													</Typography>
													<Box display={'flex'} alignItems={'center'}>
														<Box
															onClick={() => setNight(night - 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginLeft: 10,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															-
														</Box>
														<Box
															style={{
																backgroud: 'green',
																marginLeft: 10,
																marginRight: 10,
															}}>
															{night}
														</Box>
														<Box
															onClick={() => setNight(night + 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginRight: 10,
																marginLeft: 5,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															+
														</Box>
													</Box>
												</Box>
												<Box
													display={'flex'}
													justifyContent={'space-between'}
													alignItems={'center'}>
													<Typography sx={{ p: 2, fontSize: 12 }}>
														No. of Rooms<br></br>
													</Typography>
													<Box display={'flex'} alignItems={'center'}>
														<Box
															onClick={() => setRoom(room - 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginLeft: 10,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															-
														</Box>
														<Box
															style={{
																backgroud: 'green',
																marginLeft: 10,
																marginRight: 10,
															}}>
															{room}
														</Box>
														<Box
															onClick={() => setRoom(room + 1)}
															style={{
																border: '1px solid lightskyblue',
																padding: '4px 20px',
																marginRight: 10,
																marginLeft: 5,
																borderRadius: 5,
																cursor: 'pointer',
															}}>
															+
														</Box>
													</Box>
												</Box> */}
										</Popover>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={2}>
								<Box
									onClick={handleTBO}
									// onClick={() => navigate('/hotelresult')}
									style={{
										backgroundColor: 'rgba(77, 165, 40, 1)',
										textAlign: 'center',
										padding: '30px',
										borderRadius: '5px',
										cursor: 'pointer',
									}}
									textAlign={'center'}>
									<img
										src={search}
										style={{
											height: 25,
											width: 25,
											display: 'inline',
											objectFit: 'contain',
										}}
									/>
								</Box>
							</Grid>
						</Grid>
					</Container>
				</Box>
			) : (
				''
			)}
			<Box
				style={{
					// paddingLeft: matches ? '' : 80,
					// paddingRight: matches ? '' : 80,
					marginTop: matches ? 0 : 20,
				}}>
				<Container>
					<Grid container spacing={4}>
						{matches ? (
							''
						) : (
							<Grid item xs={12} lg={3}>
								<FilterSectionHotel setResult={setResult} />
							</Grid>
						)}
						<Grid item xs={12} lg={9}>
							<Box
								display={'flex'}
								justifyContent={'space-between'}
								style={{
									backgroundColor: 'rgba(239, 241, 238, 1)',
									padding: 15,
									borderRadius: 5,
								}}>
								<Box>Sort By</Box>
							</Box>
							{loadinghotel ? (
								<>
									{Array.from(Array(10)).map((i) => {
										return <FlightRowSkeleton />;
									})}
								</>
							) : result?.length > 0 ? (
								''
							) : (
								hotelResult?.result?.HotelSearchResult?.HotelResults?.length >
									0 &&
								hotelResult?.result?.HotelSearchResult?.HotelResults?.map(
									(i) => {
										return (
											<Grid
												container
												style={{
													boxShadow: '0px 3.37px 12px 0px rgba(0, 0, 0, 0.15)',
													padding: 15,
													marginTop: 15,
													borderRadius: 5,
												}}>
												<Grid item xs={12} lg={8}>
													<Box
														display='flex'
														// justifyContent='space-between'
													>
														<Box>
															<img
																src={i.HotelPicture}
																style={{
																	height: '250px',
																	borderRadius: 15,
																	width: '250px',
																}}
															/>
														</Box>
														<Box ml={2}>
															<h1
																style={{
																	color: 'rgba(0, 102, 146, 1)',
																	fontSize: 16,
																	fontFamily: 'Outfit',
																	fontWeight: 'bold',
																	textOverflow: 'ellipsis',
																	overflow: 'hidden',
																	width: '300px',
																	height: '1.2em',
																	whiteSpace: 'nowrap',
																}}>
																{i.HotelName}
															</h1>
															<Box display={'flex'} mt={1}>
																<img src={loc} />
																<p
																	title={i.HotelAddress}
																	style={{
																		color: 'rgba(0, 102, 146, 1)',
																		fontSize: 12,
																		fontFamily: 'Outfit',
																		fontWeight: 'bold',
																		textOverflow: 'ellipsis',
																		overflow: 'hidden',
																		width: '300px',
																		height: '1.2em',
																		whiteSpace: 'nowrap',
																	}}>
																	{i.HotelAddress}
																</p>
															</Box>

															<Box
																display={'flex'}
																mt={1.5}
																alignItems='center'>
																<Box display={'flex'} alignItems={'center'}>
																	<span
																		style={{
																			backgroundColor: 'rgba(0, 102, 146, 1)',
																			padding: 3,
																			fontSize: 10,
																			color: 'white',
																			borderRadius: 2,
																		}}>
																		{i.StarRating}
																	</span>
																	<p
																		style={{
																			color: 'rgba(0, 102, 146, 1)',
																			fontSize: 12,
																			marginLeft: 5,
																		}}>
																		Excellent
																	</p>
																</Box>
																<p
																	style={{
																		color: 'rgba(0, 102, 146, 1)',
																		fontSize: 12,
																		marginLeft: 5,
																	}}>
																	Rating
																</p>
															</Box>
															<Box display={'flex'} mt={1.5}>
																{/* <img src={tick} /> */}
																{/* <p
																	style={{
																		color: 'rgba(4, 167, 76, 1)',
																		fontSize: 12,
																		marginLeft: 5,
																	}}>
																	Breakfast Included
																</p> */}
															</Box>
															<Box display={'flex'}>
																{/* <img src={tick} /> */}
																{/* <p
																	style={{
																		color: 'rgba(4, 167, 76, 1)',
																		fontSize: 12,
																		marginLeft: 5,
																	}}>
																	Free Cancellation
																</p> */}
															</Box>
														</Box>
													</Box>
												</Grid>
												<Grid item xs={1.5}></Grid>
												<Grid item xs={12} lg={2.5}>
													<Box>
														{/* <Box
															style={{
																backgroundColor: 'rgba(252, 235, 235, 1)',
																padding: 8,
																alignItems: 'center',
																border: '0.5px solid rgba(255, 47, 47, 1)',
																borderRadius: 25,
																// width: '20%',
																marginTop: 20,
															}}>
															<p
																style={{
																	textAlign: 'center',
																	color: 'rgba(255, 47, 47, 1)',
																	fontSize: matches ? 8 : 12,
																}}>
																2 rooms left
															</p>
														</Box> */}
														<p
															style={{
																textAlign: 'end',
																marginTop: 10,
																textDecoration: 'line-through',
															}}>
															₹ {Number(Math.round(i.Total_Amount))}
														</p>
														<h1
															style={{
																textAlign: 'end',
																fontWeight: 'bold',
																fontSize: 20,
																marginTop: 10,
															}}>
															₹{' '}
															{Number(
																Math.round(
																	Number(i.Total_Amount) -
																		Number(i.MFB_Discount)
																)
															)}
														</h1>
														<p style={{ fontSize: 12, textAlign: 'end' }}>
															{/* + ₹3600 taxes & fees per night */}
														</p>
														<Button
															onClick={() =>
																navigate('/hotelreview', {
																	state: {
																		item: i,
																		traceId:
																			hotelResult?.result?.HotelSearchResult
																				?.TraceId,
																	},
																})
															}
															style={{
																backgroundColor: 'rgba(77, 165, 40, 1)',
																color: 'white',
																width: '100%',
																marginTop: 5,
															}}>
															Book
														</Button>
													</Box>
												</Grid>
											</Grid>
										);
									}
								)
							)}
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Footer />
		</>
	);
};

export default HotelResultPage;
