import React, { useEffect, useState } from 'react';
import Footer from '../components/resuableComponents/Footer';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import ExploreWorld from '../components/resuableComponents/ExploreWorld';
import Activities from '../components/resuableComponents/Activities';
import NewSlider from '../components/resuableComponents/NewSlider';
import TopDest from '../components/resuableComponents/TopDest';
import BottomBanner from '../components/resuableComponents/BottomBanner';
import Package from '../components/resuableComponents/Package';
import europe from '../assets/images/europe.webp';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';
import {
	setAddBookingData,
	setBookingONWARDData,
	setBookingOnewayData,
	setBookingRETURNData,
	setFormData,
	setSelectedHeaderTab,
} from '../reducers/UiReducer';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import {
	showdomDispatch,
	showintDispatch,
	showtourdestDispatch,
} from '../reducers/HomeReducer';
import LoaderModal from '../components/resuableComponents/LoaderModal';
import { walletBalanceAgentDispatch } from '../reducers/AgentReducer';
import TopInternationl from '../components/resuableComponents/TopInternationl';

const FlightHomePage = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { loading, agentInfo } = useSelector((state) => state.agent);
	const { dom, int } = useSelector((state) => state.home);
	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(showtourdestDispatch());
		sessionStorage.removeItem('bookingDetailRETURN');
		sessionStorage.removeItem('bookingDetailONWARD');
		sessionStorage.removeItem('bookingDataONWARD');
		sessionStorage.removeItem('bookingDataRETURN');
		sessionStorage.removeItem('addBookingData');
		sessionStorage.removeItem('formData');
		dispatch(setBookingRETURNData(null));
		dispatch(setBookingONWARDData(null));
		dispatch(setAddBookingData(null));
		dispatch(setBookingOnewayData(null));
		dispatch(setFormData(null));
		dispatch(showdomDispatch());
		dispatch(showintDispatch());
		dispatch(
			walletBalanceAgentDispatch(
				agentInfo?.result?.result?.email,
				agentInfo?.result?.result?.id
			)
		);
		if (location.pathname === '/flightsbooking') {
			dispatch(setSelectedHeaderTab('flight'));
		}
	}, []);
	return (
		<>
			<Navbar />
			<MNavbar />
			<NewSlider />
			<ExploreWorld />
			{/* <Package /> */}
			{/* <Activities /> */}
			<TopDest />
			<Container style={{ marginTop: 50 }}>
				<img src={europe} />
			</Container>
			<TopInternationl />
			<BottomBanner />
			<Footer />
			{loading ? <LoaderModal /> : ''}
		</>
	);
};

export default FlightHomePage;
