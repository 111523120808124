import React, { useEffect } from 'react';
import {
	Grid,
	Container,
	Button,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import travelG from '../../assets/images/travelG.svg';
import personal from '../../assets/images/personal.svg';
import loca from '../../assets/images/location.svg';
import { useSelector, useDispatch } from 'react-redux';
import { generalDispatch } from '../../reducers/HomeReducer';
const ExploreWorld = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { general } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(generalDispatch());
	}, []);
	return (
		<div>
			<Container style={{ marginTop: 20 }}>
				<Grid container spacing={4}>
					<Grid item xs={12} lg={6}>
						<img
							src={general?.result[0]?.imgpath}
							style={{ borderRadius: 5, width: '100%', height: 370 }}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						lg={6}
						style={{ textAlign: matches ? 'center' : '' }}>
						<h1
							style={{
								fontSize: matches ? 38 : 40,
								fontFamily: 'Plus Jakarta Sans',
								fontWeight: 700,
							}}>
							{general?.result[0]?.desc}
							{/* <span style={{ color: 'rgba(182, 194, 212, 1)' }}>
								{` ${'journeys!'}`}
							</span> */}
						</h1>
						<Grid container spacing={4} style={{ marginTop: 10 }}>
							<Grid
								item
								xs={12}
								lg={6}
								style={{ textAlign: matches ? '-webkit-center' : '' }}>
								<Button
									style={{
										backgroundColor: 'rgba(77, 165, 40, 1)',
										color: 'white',
										padding: '15px 30px',
										marginTop: 25,
									}}>
									More about us
								</Button>
							</Grid>
							<Grid
								item
								xs={12}
								lg={6}
								style={{ textAlign: matches ? '-webkit-center' : '' }}></Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default ExploreWorld;
