import React, { useEffect } from 'react';
import { Container, Grid, Box, useTheme, useMediaQuery } from '@mui/material';
import arrowGreen from '../../assets/images/arrowGreen.svg';
import { useDispatch, useSelector } from 'react-redux';
import { showtourdestdomDispatch } from '../../reducers/HomeReducer';

const TopInternationl = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { tourdest, int } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(showtourdestdomDispatch());
	}, []);
	return (
		<>
			<div style={{ marginTop: 50 }}>
				<Container style={{ marginTop: 20 }}>
					<Grid container>
						<Grid item xs={2}></Grid>
						<Grid item xs={8}>
							<h1
								style={{
									textAlign: 'center',
									color: 'rgba(8, 30, 42, 1)',
									fontSize: matches ? 38 : 40,
									fontWeight: 'bold',
								}}>
								Explore the World - Unforgettable International Journey Await!
							</h1>
						</Grid>
						<Grid item xs={2}></Grid>
					</Grid>
				</Container>
			</div>
			<Container style={{ marginTop: 50 }}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6} lg={4}>
						<img
							src={int?.result?.[0].imgpath}
							style={{ borderRadius: 5, width: '100%', height: 380 }}
						/>
					</Grid>
					<Grid item xs={12} md={6} lg={8}>
						<Grid container spacing={2}>
							{tourdest?.result?.map((i, index) => {
								return (
									<Grid item xs={12} md={6} lg={3}>
										<div className='card'>
											<img src={i.imgpath} alt='Kerala' />
											<div className='price'>₹{i.price}</div>
											<div className='duration'>{i.tour}</div>

											<div className='details'>
												<div className='title'>{i.title}</div>
											</div>
										</div>
									</Grid>
								);
							})}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

export default TopInternationl;
