import React, { useState } from 'react';
import {
	Box,
	Grid,
	Skeleton,
	TextField,
	Button,
	Typography,
} from '@mui/material';
import flightlogo from '../../assets/images/flightlogo.svg';
import arrivalflight from '../../assets/images/arrialflight.svg';
import travelers from '../../assets/images/traveler.svg';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import Popover from '@mui/material/Popover';
import cal from '../../assets/images/cal.svg';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import moment from 'moment';
import {
	setOnward,
	setReturn,
	setSelectedArrival,
	setSelectedDepart,
	setTrip,
} from '../../reducers/UiReducer';
import { cityDispatch } from '../../reducers/HomeReducer';
import { DatePicker } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

const dateFormat = 'DD-MM-YYYY';

const ModifySearch = () => {
	const { city } = useSelector((state) => state.home);
	const { depart, arrival, trip } = useSelector((state) => state.ui);
	const { loading } = useSelector((state) => state.tbo);
	const { agentInfo } = useSelector((state) => state.agent);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const [departDate, setDepartDate] = useState('');
	const [departDateReturn, setDepartDateReturn] = useState('');
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [adult, setAdult] = useState(location.state.AdultCount);
	const [child, setChild] = useState(location.state.ChildCount);
	const [infant, setInfant] = useState(location.state.ChildCount);
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	function disabledDate(current) {
		return moment().add(-1, 'days') >= current;
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const onChange = (date, dateString) => {
		setDepartDate(dateString);
	};
	const onChangeReturn = (date, dateString) => {
		setDepartDateReturn(dateString);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleChange = (event) => {
		let value = event.target.value.toLowerCase();
		let cityData = {
			keywords: value,
			limits: 10,
		};
		dispatch(cityDispatch(cityData));
	};
	const handleTBOModify = () => {
		if (trip === 'oneway') {
			let formData = {
				AdultCount: adult,
				ChildCount: child,
				InfantCount: infant,
				DirectFlight: false,
				OneStopFlight: false,
				JourneyType: 1,
				PreferredAirlines: null,
				Segments: [
					{
						Origin: depart.airport_code,
						Destination: arrival.airport_code,
						FlightCabinClass: 1,
						PreferredDepartureTime: departDate
							? `${departDate.split('-')[2]}-${departDate.split('-')[1]}-${
									departDate.split('-')[0]
							  }`
							: location.state.Segments[0].PreferredDepartureTime,
					},
				],
				Sources: 'WEB',
				agent_id: agentInfo?.result?.result?.id,
			};
			navigate('/flightresult', { state: formData });
		} else {
			if (depart.country_code === 'IN' && arrival.country_code === 'IN') {
				dispatch(setOnward(null));
				dispatch(setReturn(null));
				let formData = {
					AdultCount: adult,
					ChildCount: child,
					InfantCount: infant,
					DirectFlight: false,
					OneStopFlight: false,
					JourneyType: 2,
					PreferredAirlines: null,
					Segments: [
						{
							Origin: depart.airport_code,
							Destination: arrival.airport_code,
							FlightCabinClass: 1,
							PreferredDepartureTime: departDate
								? `${departDate.split('-')[2]}-${departDate.split('-')[1]}-${
										departDate.split('-')[0]
								  }`
								: location.state.Segments[0].PreferredDepartureTime,
						},
						{
							Origin: arrival.airport_code,
							Destination: depart.airport_code,
							FlightCabinClass: 1,
							PreferredDepartureTime: departDateReturn
								? `${departDateReturn.split('-')[2]}-${
										departDateReturn.split('-')[1]
								  }-${departDateReturn.split('-')[0]}`
								: location.state.Segments[1].PreferredDepartureTime,
						},
					],
					Sources: 'WEB',
					agent_id: agentInfo?.result?.result?.id,
				};
				navigate('/flightroundtripresult', { state: formData });
			} else {
				let formData = {
					AdultCount: adult,
					ChildCount: child,
					InfantCount: infant,
					DirectFlight: false,
					OneStopFlight: false,
					JourneyType: 2,
					PreferredAirlines: null,
					Segments: [
						{
							Origin: depart.airport_code,
							Destination: arrival.airport_code,
							FlightCabinClass: 1,
							PreferredDepartureTime: departDate
								? `${departDate.split('-')[2]}-${departDate.split('-')[1]}-${
										departDate.split('-')[0]
								  }`
								: location.state.Segments[0].PreferredDepartureTime,
						},
						{
							Origin: arrival.airport_code,
							Destination: depart.airport_code,
							FlightCabinClass: 1,
							PreferredDepartureTime: departDateReturn
								? `${departDateReturn.split('-')[2]}-${
										departDateReturn.split('-')[1]
								  }-${departDateReturn.split('-')[0]}`
								: location.state.Segments[1].PreferredDepartureTime,
						},
					],
					Sources: 'WEB',
					agent_id: agentInfo?.result?.result?.id,
				};
				navigate('/flightroundInternationaltripresult', { state: formData });
			}
		}
	};
	return (
		<>
			<Box
				style={{
					backgroundColor: 'rgba(239, 241, 238, 1)',
					padding: 10,
				}}>
				<Box style={{ paddingLeft: 100, paddingRight: 100 }}>
					<Box display={'flex'} alignItems={'center'} mb={1}>
						<Box
							onClick={() => dispatch(setTrip('oneway'))}
							style={{
								backgroundColor: trip === 'oneway' ? 'grey' : '',
								fontSize: 12,
								padding: '0px 10px',
								color: trip === 'oneway' ? 'white' : '',
								borderRadius: 3,
								cursor: 'pointer',
							}}>
							One-Way
						</Box>
						<Box
							onClick={() => dispatch(setTrip('roundtrip'))}
							ml={2}
							style={{
								backgroundColor: trip === 'roundtrip' ? 'grey' : '',
								fontSize: 12,
								padding: '0px 10px',
								color: trip === 'roundtrip' ? 'white' : '',
								borderRadius: 3,
								cursor: 'pointer',
							}}>
							Round-Trip
						</Box>
					</Box>
					<Grid container alignItems={'center'} spacing={2}>
						<Grid item xs={trip === 'roundtrip' ? 2.5 : 3}>
							{loading ? (
								<Skeleton variant='rounded' width={'100%'} height={60} />
							) : (
								<Box display={'flex'} alignItems={'center'}>
									<img
										src={flightlogo}
										style={{ height: 30, width: 30, objectFit: 'contain' }}
									/>
									<Box style={{ width: '100%' }}>
										<span
											style={{
												// color: 'white',
												marginLeft: 16,
												fontSize: 14,
											}}>
											Departure Airport
										</span>
										<Autocomplete
											id='country-select-demo'
											sx={{
												width: '100%',
												marginLeft: 2,
												// marginTop: 1,

												'& .MuiFormLabel-root': {
													// color: 'white',
												},
												'& .MuiInputBase-input': {
													// color: 'rgba(207, 201, 201, 1)',
													// fontWeight: 'bold',
												},
												'& .MuiSvgIcon-root': {
													// color: 'white',
												},
											}}
											options={
												city && city.result.length > 0 ? city.result : []
											}
											autoHighlight
											ListboxProps={{
												className: 'myCustomList',
											}}
											value={depart}
											getOptionLabel={(option) =>
												`${option.city_name}(${option.airport_code}) `
											}
											renderOption={(props, option) => (
												<Box
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center',
													}}
													component='li'
													sx={{
														'& > img': {
															mr: 2,
															flexShrink: 0,
															fontSize: 14,
														},
													}}
													{...props}>
													<Box>
														<Box display={'flex'}>
															<img
																src={flightlogo}
																style={{ width: 20, height: 20 }}
															/>
															<p style={{ fontSize: 13, marginLeft: 5 }}>
																{option.city_name} - {option.airport_code}
															</p>
														</Box>
														<p
															style={{
																fontSize: 13,
																marginLeft: 25,
																fontFamily: 'Outfit',
																textOverflow: 'ellipsis',
																overflow: 'hidden',
																width: '200px',
																whiteSpace: 'nowrap',
															}}>
															{option.airport_name} airport
														</p>
													</Box>
													<img
														loading='lazy'
														width='25'
														height='25'
														src={`https://flagcdn.com/w20/${option.country_code.toLowerCase()}.png`}
														srcSet={`https://flagcdn.com/w40/${option.country_code.toLowerCase()}.png 2x`}
														alt=''
													/>
												</Box>
											)}
											onChange={(event, value) => {
												dispatch(setSelectedDepart(value));
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													id='standard-basic'
													// label='To'
													variant='standard'
													onChange={handleChange}
													style={{ fontSize: 12, borderBottom: 'none' }}
													inputProps={{
														...params.inputProps,
														autoComplete: 'new-password', // disable autocomplete and autofill
													}}
												/>
											)}
										/>
										<p style={{ marginLeft: 16, fontSize: 14 }}>
											{depart.airport_name} Airport
										</p>
									</Box>
								</Box>
							)}
						</Grid>
						<Grid item xs={trip === 'roundtrip' ? 2.5 : 3}>
							{loading ? (
								<Skeleton variant='rounded' width={'100%'} height={60} />
							) : (
								<Box display={'flex'} alignItems={'center'}>
									<img
										src={arrivalflight}
										style={{ height: 30, width: 30, objectFit: 'contain' }}
									/>
									<Box style={{ width: '100%' }}>
										<span
											style={{
												// color: 'white',
												marginLeft: 16,
												fontSize: 14,
											}}>
											Arrival Airport
										</span>
										<Autocomplete
											id='country-select-demo'
											sx={{
												width: '100%',
												marginLeft: 2,
												// marginTop: 1,

												'& .MuiFormLabel-root': {
													// color: 'white',
												},
												'& .MuiInputBase-input': {
													// color: 'rgba(207, 201, 201, 1)',
													// fontWeight: 'bold',
												},
												'& .MuiSvgIcon-root': {
													// color: 'white',
												},
											}}
											options={
												city && city.result.length > 0 ? city.result : []
											}
											autoHighlight
											ListboxProps={{
												className: 'myCustomList',
											}}
											value={arrival}
											getOptionLabel={(option) =>
												`${option.city_name}(${option.airport_code}) `
											}
											renderOption={(props, option) => (
												<Box
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center',
													}}
													component='li'
													sx={{
														'& > img': {
															mr: 2,
															flexShrink: 0,
															fontSize: 14,
														},
													}}
													{...props}>
													<Box>
														<Box display={'flex'}>
															<img
																src={flightlogo}
																style={{ width: 20, height: 20 }}
															/>
															<p style={{ fontSize: 13, marginLeft: 5 }}>
																{option.city_name} - {option.airport_code}
															</p>
														</Box>
														<p
															style={{
																fontSize: 13,
																marginLeft: 25,
																fontFamily: 'Outfit',
																textOverflow: 'ellipsis',
																overflow: 'hidden',
																width: '200px',
																whiteSpace: 'nowrap',
															}}>
															{option.airport_name} airport
														</p>
													</Box>
													<img
														loading='lazy'
														width='25'
														height='25'
														src={`https://flagcdn.com/w20/${option.country_code.toLowerCase()}.png`}
														srcSet={`https://flagcdn.com/w40/${option.country_code.toLowerCase()}.png 2x`}
														alt=''
													/>
												</Box>
											)}
											onChange={(event, value) => {
												dispatch(setSelectedArrival(value));
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													id='standard-basic'
													// label='To'
													variant='standard'
													onChange={handleChange}
													style={{ fontSize: 12, borderBottom: 'none' }}
													inputProps={{
														...params.inputProps,
														autoComplete: 'new-password', // disable autocomplete and autofill
													}}
												/>
											)}
										/>
										<p style={{ marginLeft: 16, fontSize: 14 }}>
											{arrival?.airport_name} Airport
										</p>
									</Box>
								</Box>
							)}
						</Grid>
						<Grid item xs={2}>
							{loading ? (
								<Skeleton variant='rounded' width={'100%'} height={60} />
							) : (
								<Box display={'flex'} alignItems={'center'}>
									<img
										src={cal}
										style={{
											height: 25,
											width: 25,
											objectFit: 'contain',
											// marginTop: 5,
										}}
									/>
									<Box className='picker'>
										<span
											style={{
												// color: 'white',
												marginLeft: 10,
												fontSize: 14,
											}}>
											Departure
										</span>
										<DatePicker
											disabledDate={disabledDate}
											onChange={onChange}
											placeholder=''
											defaultValue={dayjs(
												`${
													location.state.Segments[0].PreferredDepartureTime.split(
														'-'
													)[2]
												}-${
													location.state.Segments[0].PreferredDepartureTime.split(
														'-'
													)[1]
												}-${
													location.state.Segments[0].PreferredDepartureTime.split(
														'-'
													)[0]
												}`,
												dateFormat
											)}
											format={dateFormat}
											style={{
												width: '100%',
												padding: 8,
												border: 'none',
												borderBottom: '1px solid',
												borderRadius: '1px',
												boxShadow: 'none',
												background: 'transparent',
											}}
										/>
										<p style={{ marginLeft: 16 }}>
											{new Date(
												location.state.Segments[0].PreferredDepartureTime
											).toLocaleString('en-us', { weekday: 'long' })}
										</p>
									</Box>
								</Box>
							)}
						</Grid>
						{trip === 'roundtrip' ? (
							<>
								<Grid item xs={2}>
									{loading ? (
										<Skeleton variant='rounded' width={'100%'} height={60} />
									) : (
										<Box display={'flex'} alignItems={'center'}>
											<img
												src={cal}
												style={{
													height: 25,
													width: 25,
													objectFit: 'contain',
													// marginTop: 5,
												}}
											/>
											<Box className='picker'>
												<span
													style={{
														// color: 'white',
														marginLeft: 10,
														fontSize: 14,
													}}>
													Arrival
												</span>
												<DatePicker
													disabledDate={disabledDate}
													onChange={onChangeReturn}
													placeholder=''
													defaultValue={
														location.state.Segments?.length > 1
															? dayjs(
																	`${
																		location.state.Segments[1].PreferredDepartureTime.split(
																			'-'
																		)[2]
																	}-${
																		location.state.Segments[1].PreferredDepartureTime.split(
																			'-'
																		)[1]
																	}-${
																		location.state.Segments[1].PreferredDepartureTime.split(
																			'-'
																		)[0]
																	}`,
																	dateFormat
															  )
															: ''
													}
													format={dateFormat}
													style={{
														width: '100%',
														padding: 8,
														border: 'none',
														borderBottom: '1px solid',
														borderRadius: '1px',
														boxShadow: 'none',
														background: 'transparent',
													}}
												/>
												<p style={{ marginLeft: 16, fontSize: 14 }}>
													{location.state.Segments?.length > 1
														? new Date(
																location.state.Segments[1].PreferredDepartureTime
														  ).toLocaleString('en-us', { weekday: 'long' })
														: 'DATE'}
												</p>
											</Box>
										</Box>
									)}
								</Grid>
							</>
						) : (
							''
						)}
						<Grid item xs={2}>
							{loading ? (
								<Skeleton variant='rounded' width={'100%'} height={60} />
							) : (
								<Box display={'flex'}>
									<img
										src={travelers}
										style={{
											height: 25,
											width: 25,
											objectFit: 'contain',
											marginTop: 5,
										}}
									/>
									<Box>
										<h2
											style={{
												// color: 'white',
												marginLeft: 10,
												fontSize: 14,
											}}>
											Travellers & Class
										</h2>
										<Button
											style={{
												backgroud: 'transparent',
												color: 'rgba(29, 29, 29, 1)',
												textTransform: 'capitalize',
												// fontWeight: 'bold',
											}}
											aria-describedby={id}
											variant='text'
											onClick={handleClick}>
											{adult} Adult {child} Child
										</Button>
										<Popover
											id={id}
											open={open}
											style={{ marginTop: 5, padding: 10 }}
											anchorEl={anchorEl}
											onClose={handleClose}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'left',
											}}>
											<Box
												display={'flex'}
												justifyContent={'space-between'}
												alignItems={'center'}>
												<Typography sx={{ p: 2, fontSize: 12 }}>
													ADULTS (12y +)<br></br>
													<span style={{ fontSize: 10 }}>
														on the day of travel
													</span>
												</Typography>
												<Box display={'flex'} alignItems={'center'}>
													<Box
														onClick={() => setAdult(adult - 1)}
														style={{
															border: '1px solid lightskyblue',
															padding: '4px 20px',
															marginLeft: 10,
															borderRadius: 5,
															cursor: 'pointer',
														}}>
														-
													</Box>
													<Box
														style={{
															backgroud: 'green',
															marginLeft: 10,
															marginRight: 10,
														}}>
														{adult}
													</Box>
													<Box
														onClick={() => setAdult(adult + 1)}
														style={{
															border: '1px solid lightskyblue',
															padding: '4px 20px',
															marginRight: 10,
															marginLeft: 5,
															borderRadius: 5,
															cursor: 'pointer',
														}}>
														+
													</Box>
												</Box>
											</Box>
											<Box
												display={'flex'}
												justifyContent={'space-between'}
												alignItems={'center'}>
												<Typography sx={{ p: 2, fontSize: 12 }}>
													CHILDS (2y-12y)<br></br>
													<span style={{ fontSize: 10 }}>
														on the day of travel
													</span>
												</Typography>
												<Box display={'flex'} alignItems={'center'}>
													<Box
														onClick={() => setChild(child - 1)}
														style={{
															border: '1px solid lightskyblue',
															padding: '4px 20px',
															marginLeft: 10,
															borderRadius: 5,
															cursor: 'pointer',
														}}>
														-
													</Box>
													<Box
														style={{
															backgroud: 'green',
															marginLeft: 10,
															marginRight: 10,
														}}>
														{child}
													</Box>
													<Box
														onClick={() => setChild(child + 1)}
														style={{
															border: '1px solid lightskyblue',
															padding: '4px 20px',
															marginRight: 10,
															marginLeft: 5,
															borderRadius: 5,
															cursor: 'pointer',
														}}>
														+
													</Box>
												</Box>
											</Box>
											<Box
												display={'flex'}
												justifyContent={'space-between'}
												alignItems={'center'}>
												<Typography sx={{ p: 2, fontSize: 12 }}>
													INFANTS (below 2y)<br></br>
													<span style={{ fontSize: 10 }}>
														on the day of travel
													</span>
												</Typography>
												<Box display={'flex'} alignItems={'center'}>
													<Box
														onClick={() => setInfant(infant - 1)}
														style={{
															border: '1px solid lightskyblue',
															padding: '4px 20px',
															marginLeft: 10,
															borderRadius: 5,
															cursor: 'pointer',
														}}>
														-
													</Box>
													<Box
														style={{
															backgroud: 'green',
															marginLeft: 10,
															marginRight: 10,
														}}>
														{infant}
													</Box>
													<Box
														onClick={() => setInfant(infant + 1)}
														style={{
															border: '1px solid lightskyblue',
															padding: '4px 20px',
															marginRight: 10,
															marginLeft: 5,
															borderRadius: 5,
															cursor: 'pointer',
														}}>
														+
													</Box>
												</Box>
											</Box>
											<FormControl style={{ padding: 10 }}>
												<FormLabel
													id='demo-radio-buttons-group-label'
													style={{ fontSize: 14 }}>
													CHOOSE TRAVEL CLASS
												</FormLabel>
												<RadioGroup
													aria-labelledby='demo-radio-buttons-group-label'
													defaultValue='Economy/Premium Economy'
													name='radio-buttons-group'>
													<FormControlLabel
														sx={{
															'& .MuiTypography-root ': {
																fontSize: 12,
															},
														}}
														value='Economy/Premium Economy'
														control={<Radio size='small' />}
														label='Economy/Premium Economy'
													/>
													<FormControlLabel
														sx={{
															'& .MuiTypography-root ': {
																fontSize: 12,
															},
														}}
														value='Premium Economy'
														control={<Radio size='small' />}
														label='Premium Economy'
													/>
													<FormControlLabel
														sx={{
															'& .MuiTypography-root ': {
																fontSize: 12,
															},
														}}
														value='Business'
														control={<Radio size='small' />}
														label='Business'
													/>
												</RadioGroup>
											</FormControl>
										</Popover>
									</Box>
								</Box>
							)}
						</Grid>
						<Grid item xs={trip === 'roundtrip' ? 1 : 2}>
							{loading ? (
								<Skeleton variant='rounded' width={200} height={60} />
							) : (
								<Button
									onClick={handleTBOModify}
									style={{
										background: 'rgba(77, 165, 40, 1)',
										fontSize: 14,
										color: 'white',
										width: '100%',
										padding: 10,
									}}>
									Search Again
								</Button>
							)}
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	);
};

export default ModifySearch;
