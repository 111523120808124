import React, { useState, useEffect } from 'react';
import './stylesLPmobile.scss';
import Logo from '../../../assets/images/LOGO.svg';
import LogoW from '../../../assets/images/LOGO.svg';
import {
	Box,
	Grid,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';

const HeaderMobileWeb = (props) => {
	const [state, setState] = useState(false);
	const headerStateChange = () => {
		setState(!state);
	};
	const { active, setResult, SetRule } = props;
	const { loading, tbo } = useSelector((state) => state.tbo);
	const [airline, setairline] = useState('');
	const [airlineT, setAirineT] = useState(false);
	const [refund, setRefund] = useState(false);
	const [nrefund, setNRefund] = useState(false);
	const [stop, setStop] = useState('');
	const [time, setTime] = useState('');
	useEffect(() => {
		setairline('');
		setRefund(false);
		setNRefund(false);
		setStop('');
		setTime('');
	}, []);
	let filterAirlineTBO = [];
	if (loading) {
		console.log('loading...');
	} else {
		if (
			tbo &&
			tbo?.result &&
			tbo?.result?.Response &&
			tbo?.result?.Response?.Results[0].length > 0
		) {
			filterAirlineTBO = [
				...new Set(
					tbo &&
						tbo?.result &&
						tbo?.result?.Response &&
						tbo?.result?.Response?.Results[0].length > 0 &&
						tbo?.result?.Response?.Results[0].map(
							(item) =>
								`${item?.AirlineCode}-${item.Segments[0][0]?.Airline?.AirlineName}`
						)
				),
			];
		}
	}
	const handleAirline = (event) => {
		SetRule(event.target.checked);
		setairline(event.target.name);
		if (airline === event.target.name) {
			setResult([]);
			setairline('');
		} else {
			setNRefund(false);
			setRefund(false);
			setStop('');
			setTime('');
			let filterCombineStop = [];
			let filterDataStop = [];
			let tboFilter = [];
			if (tbo) {
				tbo &&
					tbo &&
					tbo.result &&
					tbo.result.Response &&
					tbo.result.Response.Results[0].length > 0 &&
					tbo.result.Response.Results[0].map((item) => {
						if (item.AirlineCode === event.target.name) {
							tboFilter.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tboFilter];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first =
						a.Supplier === 'TBO'
							? Number(
									Number(Math.round(a.Fare.Total_Amount)) -
										Number(Math.round(a.Fare.MFB_Discount))
							  )
							: '';
					let second =
						b.Supplier === 'TBO'
							? Number(
									Number(Math.round(b.Fare.Total_Amount)) -
										Number(Math.round(b.Fare.MFB_Discount))
							  )
							: '';
					return first - second;
				});
			console.log('filterAilrine', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleFareR = (event) => {
		setRefund(event.target.checked);
		SetRule(event.target.checked);
		if (refund) {
			setResult([]);
		} else {
			setNRefund(false);
			setStop('');
			setTime('');
			setairline('');
			setResult([]);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tboFilter = [];
			if (tbo) {
				tbo &&
					tbo.result &&
					tbo.result.Response &&
					tbo.result.Response.Results[0].length > 0 &&
					tbo.result.Response.Results[0].map((item) => {
						if (item.IsRefundable) {
							tboFilter.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tboFilter];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: '';
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: '';
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleFareN = (event) => {
		setNRefund(event.target.checked);
		SetRule(event.target.checked);
		if (nrefund) {
			setResult([]);
		} else {
			setRefund(false);
			setStop('');
			setTime('');
			setairline('');
			setResult([]);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tboFilter = [];
			if (tbo) {
				tbo &&
					tbo.result &&
					tbo.result.Response &&
					tbo.result.Response.Results[0].length > 0 &&
					tbo.result.Response.Results[0].map((item) => {
						if (!item.IsRefundable) {
							tboFilter.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tboFilter];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: '';
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: '';
					return first - second;
				});
			console.log('stop3', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleStops = (val) => {
		SetRule(true);
		setStop(val);
		if (!val) {
			setResult([]);
			SetRule(false);
		} else {
			setNRefund(false);
			setRefund(false);
			setTime('');
			setairline('');
			let filterCombineStop = [];
			let filterDataStop = [];
			let tboFilter = [];
			if (tbo) {
				tbo &&
					tbo.result &&
					tbo.result.Response &&
					tbo.result.Response.Results[0].length > 0 &&
					tbo.result.Response.Results[0].map((item) => {
						if (item.Segments[0].length === parseInt(val)) {
							tboFilter.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tboFilter];
			console.log('Combine', filterCombineStop);
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first =
						a.Supplier === 'TBO'
							? Number(
									Number(Math.round(a.Fare.Total_Amount)) -
										Number(Math.round(a.Fare.MFB_Discount))
							  )
							: '';
					let second =
						b.Supplier === 'TBO'
							? Number(
									Number(Math.round(b.Fare.Total_Amount)) -
										Number(Math.round(b.Fare.MFB_Discount))
							  )
							: '';
					return first - second;
				});
			console.log('filterCombineStop', filterDataStop);
			setResult(filterDataStop);
		}
	};
	const handleTiming = (val) => {
		setTime(val);
		if (!val) {
			setResult([]);
		} else {
		}
	};
	return (
		<Box className='headerMW '>
			{/* <img src={Logo} alt='Logo' /> */}
			{/* <Box>Modify</Box> */}
			{active !== 'login' && (
				<>
					<Box mr={5} style={{ fontWeight: 'bold' }}>
						Filter
					</Box>
					<Box
						onClick={headerStateChange}
						className={'hamburgHMW ' + (state && ' active ')}>
						<Box className='hamburgHMWCon'>
							<span className='line One'></span>
							<span className='line Two'></span>
							<span className='line Three'></span>
						</Box>
					</Box>
				</>
			)}

			<Box className={'hamDrop ' + (state && ' active ')}>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					style={{ padding: '0px 20px', marginTop: 43 }}>
					<h1 style={{ fontSize: 25, fontWeight: 'bold' }}>Filters</h1>
				</Box>
				<Divider style={{ border: '1.33px solid #CBCBCB' }} />
				<Box style={{ padding: '0px 20px' }}>
					<h1>Departure</h1>
					<Grid container spacing={2} style={{ marginTop: 10 }}>
						<Grid item xs={6}>
							<Box
								onClick={() =>
									time === '1' ? handleTiming('') : handleTiming('1')
								}
								style={{
									backgroundColor: time === '1' ? '#4DA528' : '#F1F9FF',
									textAlign: 'center',
									padding: 10,
									color: time === '1' ? '#FBFBFB' : '',
									borderRadius: 3,
									cursor: 'pointer',
								}}>
								Before 6AM
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box
								onClick={() =>
									time === '2' ? handleTiming('') : handleTiming('2')
								}
								style={{
									backgroundColor: time === '2' ? '#4DA528' : '#F1F9FF',
									textAlign: 'center',
									padding: 10,
									color: time === '2' ? '#FBFBFB' : '',
									borderRadius: 3,
									cursor: 'pointer',
								}}>
								6AM - 12PM
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box
								onClick={() =>
									time === '3' ? handleTiming('') : handleTiming('3')
								}
								style={{
									backgroundColor: time === '3' ? '#4DA528' : '#F1F9FF',
									textAlign: 'center',
									padding: 10,
									color: time === '3' ? '#FBFBFB' : '',
									borderRadius: 3,
									cursor: 'pointer',
								}}>
								12PM - 6PM
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box
								onClick={() =>
									time === '4' ? handleTiming('') : handleTiming('4')
								}
								style={{
									backgroundColor: time === '4' ? '#4DA528' : '#F1F9FF',
									textAlign: 'center',
									padding: 10,
									color: time === '4' ? '#FBFBFB' : '',
									borderRadius: 3,
									cursor: 'pointer',
								}}>
								After 6PM
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Divider style={{ border: '1.33px solid #CBCBCB' }} />
				<Box style={{ padding: '0px 20px' }}>
					<h1>Stops</h1>
					<Grid container spacing={2} style={{ marginTop: 10 }}>
						<Grid item xs={6}>
							<Box
								onClick={() =>
									stop === '1' ? handleStops('') : handleStops('1')
								}
								style={{
									backgroundColor: stop === '1' ? '#4DA528' : '#F1F9FF',
									textAlign: 'center',
									padding: 10,
									color: stop === '1' ? '#FBFBFB' : '',
									cursor: 'pointer',
									borderRadius: 3,
								}}>
								Direct
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box
								onClick={() =>
									stop === '2' ? handleStops('') : handleStops('2')
								}
								style={{
									backgroundColor: stop === '2' ? '#4DA528' : '#F1F9FF',
									textAlign: 'center',
									padding: 10,
									color: stop === '2' ? '#FBFBFB' : '',
									cursor: 'pointer',
									borderRadius: 3,
								}}>
								1 Stop
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box
								onClick={() =>
									stop === '3' ? handleStops('') : handleStops('3')
								}
								style={{
									backgroundColor: stop === '3' ? '#4DA528' : '#F1F9FF',
									textAlign: 'center',
									padding: 10,
									cursor: 'pointer',
									color: stop === '3' ? '#FBFBFB' : '',
									borderRadius: 3,
								}}>
								2+ Stops
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Divider style={{ border: '1.33px solid #CBCBCB' }} />
				<Box style={{ padding: '0px 20px' }}>
					<h1>Preferred Airline</h1>
					<FormGroup
						style={{
							marginTop: 10,
							flexDirection: 'row',
							alignItems: 'center',
						}}>
						{filterAirlineTBO.map((i, index) => {
							return (
								<FormControlLabel
									style={{ alignItems: 'baseline' }}
									control={
										<Checkbox
											checked={airline === i.split('-')[0] ? true : false}
											onChange={handleAirline}
											name={`${i.split('-')[0]}`}
										/>
									}
									label={
										<>
											<Box style={{ display: 'flex' }}>
												<img
													style={{
														marginLeft: 5,
														borderRadius: 5,
														resize: 'contain',
													}}
													width='25px'
													height='25px'
													src={`https://nitish.musafirbazar.com/static/media/${
														i.split('-')[0]
													}.gif`}
												/>
												{/* <span
															style={{
																color: 'rgba(29, 29, 29, 1)',
																marginLeft: 10,
																fontFamily: 'Outfit',
															}}>
															{i.split('-')[1]}
														</span> */}
											</Box>
										</>
									}
								/>
							);
						})}
					</FormGroup>
				</Box>
				{/* <div className="hamDropBtm">
          <h5>Join Us On</h5>
          <div className="socialLinks">
            <ul className="btmul">
              <li>
                <i class="fab fa-twitter"></i>
              </li>
              <li>
                <i class="fab fa-linkedin-in"></i>
              </li>
              <li>
                <i class="fab fa-facebook-f"></i>
              </li>
              <li>
                <i class="fab fa-instagram"></i>
              </li>
            </ul>
          </div>
          <p> (C) Copyright 2020 by CardiClub. All rights reserved</p>
        </div> */}
			</Box>
		</Box>
	);
};

export default HeaderMobileWeb;
