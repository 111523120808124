import React, { useEffect } from 'react';
import Navbar from '../components/resuableComponents/Navbar/Navbar';
import Footer from '../components/resuableComponents/Footer';
import {
	Box,
	Container,
	Grid,
	Button,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import Steppers from '../components/resuableComponents/Steppers';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider } from 'antd';
import MNavbar from '../components/resuableComponents/Navbar/MobileNav/MNavbar';
import { useDispatch, useSelector } from 'react-redux';
import LoaderModal from '../components/resuableComponents/LoaderModal';
import BusSummaryForm from '../forms/BusSummaryForm';

const BusReview = () => {
	const location = useLocation();
	console.log('Location', location);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const { loading, loadingReturn } = useSelector((state) => state.tbo);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	function timeConvertbus(a) {
		let arhour = Math.floor(a / 60);
		if (arhour > 24) {
			arhour = arhour - 24;
		}
		let armin = a % 60;
		let format = `${arhour}:${armin === 0 ? '00' : armin}:00`;
		function formatTime(timeString) {
			const [hourString, minute] = timeString.split(':');
			const hour = +hourString % 24;
			return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM');
		}
		return formatTime(format);
	}
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div>
			<Navbar />
			<MNavbar />
			<Box
				style={{
					background: 'rgba(101, 162, 65, 1)',
					padding: matches ? 15 : 25,
				}}>
				<Container>
					<Steppers step={1} />
				</Container>
			</Box>
			<Container style={{ marginTop: 30 }}>
				<h1 style={{ fontSize: matches ? 20 : 26, fontWeight: 'bold' }}>
					Review your Booking
				</h1>
				<Grid container spacing={4}>
					<Grid item xs={12} lg={8}>
						<Box
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
								padding: 20,
							}}>
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<Box display={'flex'} alignItems={'center'}>
									<Box>
										<p style={{ fontWeight: 'bold' }}>
											{location.state.formData.departure_city}
										</p>
									</Box>
									<Box ml={2}>
										<p style={{ fontWeight: 'bold' }}>To</p>
									</Box>
									<Box ml={2}>
										<p style={{ fontWeight: 'bold' }}>
											{location.state.formData.arrival_city}
										</p>
									</Box>
								</Box>
								<Box>
									<p>{location.state.busDetail.doj.split('T')[0]}</p>
								</Box>
							</Box>
							<Box
								mt={2}
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<Box>
									<Box>
										<p>{location.state.busDetail.travels}</p>
									</Box>
									<Box>
										<p style={{ fontSize: 12 }}>
											{location.state.busDetail.busType}
										</p>
									</Box>
								</Box>
								<Box>
									<p>
										{timeConvertbus(location.state.busDetail.departureTime)}
									</p>
								</Box>
								<Box>
									<p>{timeConvertbus(location.state.busDetail.arrivalTime)}</p>
								</Box>
							</Box>
						</Box>
						{matches ? '' : <BusSummaryForm />}
					</Grid>
					<Grid item xs={12} lg={4}>
						<Box
							style={{
								boxShadow: 'rgba(0, 0, 0, 0.06) 0px 4px 21px 0px',
								padding: 10,
							}}>
							<span
								style={{
									fontSize: 18,
									fontWeight: '500',
									color: 'rgba(29, 29, 29, 1)',
								}}>
								Fare Summary
							</span>
							<Divider
								style={{ border: '1.33px solid rgba(203, 203, 203, 1)' }}
							/>
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<span
									style={{
										fontSize: 20,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
										lineHeight: '10px',
									}}>
									Base Fare
								</span>
								<span
									style={{
										fontSize: 16,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹
									{location.state.seat &&
										location.state.seat?.length > 0 &&
										location.state.seat
											?.map((item) => parseInt(item.baseFare))
											.reduce((prev, next) => prev + next)}
								</span>
							</Box>{' '}
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}></p>
							</Box>{' '}
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}></p>
							</Box>{' '}
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}></p>
							</Box>{' '}
							<Divider
								style={{ border: '1.33px solid rgba(203, 203, 203, 1)' }}
							/>
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									Convience
								</p>
								<p
									style={{
										fontSize: 14,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹ 0
								</p>
							</Box>{' '}
							<Divider
								style={{ border: '1.33px solid rgba(203, 203, 203, 1)' }}
							/>
							<Box
								display={'flex'}
								alignItems={'center'}
								justifyContent={'space-between'}>
								<span
									style={{
										fontSize: 20,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									Grand Total
								</span>
								<span
									style={{
										fontSize: 20,
										fontWeight: '500',
										color: 'rgba(29, 29, 29, 1)',
									}}>
									₹
									{location.state.seat &&
										location.state.seat?.length > 0 &&
										location.state.seat
											?.map((item) => parseInt(item.totalFare))
											.reduce((prev, next) => prev + next)}
								</span>
							</Box>
						</Box>
						{matches ? <BusSummaryForm /> : ''}
					</Grid>
				</Grid>
			</Container>
			<Footer />
			{loading || loadingReturn ? <LoaderModal /> : ''}
		</div>
	);
};

export default BusReview;
