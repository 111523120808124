import React from 'react';
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';

const LoaderModal = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				backgroundColor: '#F8F8F8AD',
			}}>
			<div
				style={{
					left: matches ? '35%' : '50%',
					top: '50%',
					zIndex: 1000,
					position: 'absolute',
					textAlign: 'center',
				}}>
				<CircularProgress style={{ color: 'rgb(77, 165, 40)' }} />
				<p style={{ marginLeft: 10 }}>Please Wait.....</p>
			</div>
		</div>
	);
};

export default LoaderModal;
