import React from 'react';
import play from '../../assets/images/play.svg';
import { Container, Box, useMediaQuery, useTheme } from '@mui/material';

const BottomBanner = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<div
			className='bg'
			style={{
				marginTop: 50,
				backgroundImage: ` linear-gradient(
		to right,
		#041b28 10.64%,
		rgba(2, 15, 22, 0.548011) 46.91%,
		rgba(0, 0, 0, 0) 99.42%
	),url(${'https://marinersforex.com/photo/botmBanner.svg'})`,
			}}>
			<Container style={{ padding: 70 }}>
				<p style={{ color: 'green' }}>Explore the World</p>
				<h1
					style={{
						fontSize: matches ? 32 : 40,
						color: 'white',
						width: matches ? '100%' : '50%',
						lineHeight: matches ? '40px' : '50px',
						marginTop: 20,
					}}>
					Adventure Awaits - Drive Into Nature and Live the Journey!
				</h1>
				<Box
					display={matches ? 'block' : 'flex'}
					alignItems={'center'}
					mt={3}
					mb={3}>
					<Box
						style={{
							backgroundColor: '#4DA528',
							padding: 20,
							borderRadius: 30,
							width: matches ? '20%' : '',
							height: matches ? '20%' : '',
						}}>
						<img src={play} />
					</Box>
					<Box
						mt={matches ? 2 : ''}
						ml={matches ? '' : 2}
						style={{
							padding: 20,
							border: '1px solid #FFFFFF66',
							color: 'white',
							borderRadius: 35,
						}}>
						Contact us at marinersforex@gmail.com
					</Box>
				</Box>
			</Container>
		</div>
	);
};

export default BottomBanner;
