import {
	TBOBookGDSONWARDDispatch,
	TBOBookLCCONWARDDispatch,
} from '../reducers/TBOReducer';
import { setBookingONWARDData } from '../reducers/UiReducer';

const tboOnwardOnlinebooking = (
	location,
	pay,
	invoice,
	farequote,
	BookingFormData,
	dispatch
) => {
	let formData = {
		TraceId: location.state.traceId,
		ResultIndex: location.state.onwardFlight.ResultIndex,
		orderId: location.state.orderId,
		paymentId: pay === 'OFFLINE' ? invoice : '',
		Passengers: BookingFormData.map((item) => {
			return {
				Title: item.title,
				FirstName: item.firstname,
				LastName: item.lastname,
				PaxType: item.PaxType,
				DateOfBirth: item.dob ? item.dob : '1998-03-09',
				Gender: item.gender === 'Male' ? 1 : 2,
				PassportNo: item.passportnumber ? item.passportnumber : '',
				PassportExpiry: item.expiry ? item.expiry : '',
				AddressLine1: 'Dhanbad',
				AddressLine2: '',
				Fare: {
					Currency: farequote
						? farequote?.result?.Response.Results.Fare.Currency
						: '',
					BaseFare: farequote
						? farequote?.result?.Response.Results.Fare.OfferedFare
						: '',
					Tax: farequote ? farequote?.result?.Response.Results.Fare.Tax : '',
					YQTax: farequote
						? farequote?.result?.Response.Results.Fare.YQTax
						: '',
					AdditionalTxnFeePub: farequote
						? farequote?.result?.Response.Results.Fare.AdditionalTxnFeePub
						: '',
					AdditionalTxnFeeOfrd: farequote
						? farequote?.result?.Response.Results.Fare.AdditionalTxnFeeOfrd
						: '',
					OtherCharges: farequote
						? farequote?.result?.Response.Results.Fare.OtherCharges
						: '',
					Discount: farequote
						? farequote?.result?.Response.Results.Fare.Discount
						: '',
					PublishedFare: farequote
						? farequote?.result?.Response.Results.Fare.PublishedFare
						: '',
					OfferedFare: farequote
						? farequote?.result?.Response.Results.Fare.OfferedFare
						: '',
					TdsOnCommission: farequote
						? farequote?.result?.Response.Results.Fare.TdsOnCommission
						: '',
					TdsOnPLB: farequote
						? farequote?.result?.Response.Results.Fare.TdsOnPLB
						: '',
					TdsOnIncentive: farequote
						? farequote?.result?.Response.Results.Fare.TdsOnIncentive
						: '',
					ServiceFee: farequote
						? farequote?.result?.Response.Results.Fare.ServiceFee
						: '',
				},
				City: 'Dhanbad',
				CountryCode: 'IN',
				CellCountryCode: '+91',
				ContactNo: location.state.passengerData
					? location.state.passengerData.phone
					: '',
				Nationality: item.nationality ? item.nationality : 'IN',
				Email: location.state.passengerData
					? location.state.passengerData.email
					: '',
				IsLeadPax: true,
				FFAirlineCode: null,
				FFNumber: '',
				GSTCompanyAddress:
					'1ST FLOOR , NEW TECH GRAND , BANKMORE,DHANBAD , JHARKHAND 826001',
				GSTCompanyContactNumber: '8877909555',
				GSTCompanyName: 'R ARYA AVIATION INDIA PVT LTD',
				GSTNumber: '20AALCR5274P1ZV',
				GSTCompanyEmail: 'sales@musafirbazar.com',
				Baggage: [],
				MealDynamic: [],
				SeatDynamic: [],
			};
		}),
	};
	console.log('FormDataOnward', formData);
	dispatch(setBookingONWARDData(formData));
	// if (location?.state?.onwardFlight) {
	// 	if (location.state.onwardFlight.IsLCC) {
	// 		dispatch(TBOBookLCCONWARDDispatch(formData, setLoading, navigate));
	// 	} else {
	// 		dispatch(TBOBookGDSONWARDDispatch(formData, setLoading, navigate));
	// 	}
	// }
};

export default tboOnwardOnlinebooking;
