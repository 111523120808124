import { Box, Grid } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';

const BookingDetailBus = () => {
	const location = useLocation();
	console.log('Location', location);
	return (
		<Box mt={3} ml={3} mr={3}>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Box
						style={{
							boxShadow:
								'rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px',
							background: '#fff',
						}}>
						<table style={{ width: '100%', borderRadius: 5 }}>
							<tbody>
								<tr>
									<td style={{ padding: 15 }}>OrderId</td>
									<td style={{ padding: 15 }}>{location?.state?.orderId}</td>
								</tr>
								{/* <tr>
									<td style={{ padding: 15 }}>Origin</td>
									<td style={{ padding: 15 }}>{origin}</td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Destination</td>
									<td style={{ padding: 15 }}>{destination}</td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Travel Date </td>
									<td style={{ padding: 15 }}>{travelDate} </td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Return Date </td>
									<td style={{ padding: 15 }}>{returnDate} </td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Journey Type </td>
									<td style={{ padding: 15 }}>{journeyType}</td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Flight Type </td>
									<td style={{ padding: 15 }}>{flightType} </td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Booking Status </td>
									<td style={{ padding: 15 }}>{bookingStatus} </td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Airline Type </td>
									<td style={{ padding: 15 }}>{airlineType} </td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Fare Type </td>
									<td style={{ padding: 15 }}>{fareType}</td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Carrier </td>
									<td style={{ padding: 15 }}>{carrier}</td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Class </td>
									<td style={{ padding: 15 }}>{location.state.class} </td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>No. of Passenger </td>
									<td style={{ padding: 15 }}>{nop}</td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Booking Website </td>
									<td style={{ padding: 15 }}>{siteURL} </td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Date of Booking</td>
									<td style={{ padding: 15 }}>{dob} </td>
								</tr> */}
							</tbody>
						</table>
					</Box>
					<Box
						mt={3}
						style={{
							boxShadow:
								'rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px',
							background: '#fff',
						}}>
						{/* <table style={{ width: '100%', borderRadius: 5 }}>
							<tbody>
								<tr>
									<th style={{ padding: 15 }}>User Type </th>
									<th style={{ padding: 15 }}>{user_type}</th>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Phone</td>
									<td style={{ padding: 15 }}>{userPhone}</td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Email</td>
									<td style={{ padding: 15 }}>{userEmail}</td>
								</tr>
							</tbody>
						</table> */}
					</Box>
				</Grid>
				{/* <Grid item xs={6}>
					<Box
						style={{
							boxShadow:
								'rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px',
							background: '#fff',
						}}>
						<table style={{ width: '100%', borderRadius: 5 }}>
							<tbody>
								<tr>
									<td style={{ padding: 15 }}>Booking Status </td>
									<td style={{ padding: 15 }}>{bookingStatus} </td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Booking PNR </td>
									<td style={{ padding: 15 }}>{bookingRefrence} </td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Ticket Supplier </td>
									<td style={{ padding: 15 }}>{supplier}</td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Payment Status </td>
									<td style={{ padding: 15 }}>
										{paymentStatus === '1' ? 'DONE' : ''}{' '}
									</td>
								</tr>
							</tbody>
						</table>
					</Box>
					<Box
						mt={3}
						style={{
							boxShadow:
								'rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px',
							background: '#fff',
						}}>
						<table style={{ width: '100%', borderRadius: 5 }}>
							<tbody>
								<tr>
									<td style={{ padding: 15 }}>Base Fare </td>
									<td style={{ padding: 15 }}>{baseFare}</td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Partner Markup </td>
									<td style={{ padding: 15 }}>{partnerMarkup} </td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Partner Markup Type </td>
									<td style={{ padding: 15 }}>{partnerMarkupType} </td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Total Fare </td>
									<td style={{ padding: 15 }}>{totalFare}</td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Agent Markup </td>
									<td style={{ padding: 15 }}>{agentMarkup}</td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Agent Markup Type </td>
									<td style={{ padding: 15 }}>{agentMarkupType} </td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Paid Service </td>
									<td style={{ padding: 15 }}>{paidServices} </td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>PG Charge </td>
									<td style={{ padding: 15 }}>{PGCharge}</td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Payable Amount </td>
									<td style={{ padding: 15 }}>{payableAmount}</td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Ticket Cost </td>
									<td style={{ padding: 15 }}>{ticketCost}</td>
								</tr>
								<tr>
									<td style={{ padding: 15 }}>Total Earn </td>
									<td style={{ padding: 15 }}>{totalEarn}</td>
								</tr>
							</tbody>
						</table>
					</Box>
				</Grid> */}
			</Grid>
			{/* <Box
				mt={3}
				style={{
					boxShadow:
						'rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px',
					background: '#fff',
				}}>
				<table style={{ width: '100%', borderRadius: 5 }}>
					<tbody>
						<tr>
							<th style={{ padding: 15 }}>SNo. </th>
							<th style={{ padding: 15 }}>Title </th>
							<th style={{ padding: 15 }}>First Name </th>
							<th style={{ padding: 15 }}>Last Name </th>
							<th style={{ padding: 15 }}>Passport Number </th>
							<th style={{ padding: 15 }}>Passport expiry </th>
							<th style={{ padding: 15 }}>Birt Date </th>
							<th style={{ padding: 15 }}>Type</th>
						</tr>

						{JSON.parse(location.state.selectedPax)?.length > 0 &&
							JSON.parse(location.state.selectedPax)?.map((i, index) => {
								return (
									<tr>
										<td style={{ padding: 15 }}>{index + 1} </td>
										<td style={{ padding: 15 }}>{i.title} </td>
										<td style={{ padding: 15 }}>{i.firstname} </td>
										<td style={{ padding: 15 }}>{i.lastname} </td>
										<td style={{ padding: 15 }}>
											{i.passportnumber ? i.passportnumber : ''}
										</td>
										<td style={{ padding: 15 }}>{i.expiry ? i.expiry : ''} </td>
										<td style={{ padding: 15 }}>{i.dob ? i.dob : ''}</td>
										<td style={{ padding: 15 }}>
											{i.PaxType === 1
												? 'ADULT'
												: i.PaxType === 2
												? 'CHILD'
												: i.PaxType === 3
												? 'INFANT'
												: ''}
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</Box> */}
			{/* <Box
				mt={3}
				mb={5}
				style={{
					boxShadow:
						'rgba(0, 0, 0, 0.06) 0px 5px 5px -3px, rgba(0, 0, 0, 0.043) 0px 8px 10px 1px, rgba(0, 0, 0, 0.035) 0px 3px 14px 2px',
					background: '#fff',
				}}>
				<table style={{ width: '100%', borderRadius: 5 }}>
					<tbody>
						<tr>
							<th style={{ padding: 15 }}>Flight.</th>
							<th style={{ padding: 15 }}>Class</th>
							<th style={{ padding: 15 }}>FareClassification</th>
							<th style={{ padding: 15 }}>Stop</th>
							<th style={{ padding: 15 }}>Baggage</th>
							<th style={{ padding: 15 }}>Cabin Baggage</th>
							<th style={{ padding: 15 }}>Departing</th>
							<th style={{ padding: 15 }}>Arriving</th>
						</tr>
						{location.state.journeyType === 'OneWay' &&
							JSON.parse(location.state.selectedFlight)?.Segments[0]?.length >
								0 &&
							JSON.parse(location.state.selectedFlight)?.Segments[0]?.map(
								(i, index) => {
									return (
										<tr>
											<td style={{ padding: 15 }}>
												<span>{i.Airline.AirlineName}</span>
												<br />
												<span>
													{i.Airline.AirlineCode}
													{i.Airline.FlightNumber}
												</span>
											</td>
											<td style={{ padding: 15 }}>Economy </td>
											<td style={{ padding: 15 }}>
												{i.FareClassification.Type}{' '}
											</td>
											<td style={{ padding: 15 }}>Non-Stop </td>
											<td style={{ padding: 15 }}>{i.Baggage} </td>
											<td style={{ padding: 15 }}>{i.CabinBaggage} </td>
											<td style={{ padding: 15 }}>
												<span>{i.Origin.DepTime}</span>
												<br />
												<span>
													{i.Origin.Airport.CityName}(
													{i.Origin.Airport.AirportCode})
												</span>
											</td>

											<td style={{ padding: 15 }}>
												<span>{i.Destination.ArrTime}</span>
												<br />
												<span>
													{i.Destination.Airport.CityName}(
													{i.Destination.Airport.AirportCode})
												</span>{' '}
											</td>
											<td style={{ padding: 15 }}>
												{i.PaxType === 1
													? 'ADULT'
													: i.PaxType === 2
													? 'CHILD'
													: i.PaxType === 3
													? 'INFANT'
													: ''}
											</td>
										</tr>
									);
								}
							)}
						{location.state.journeyType === 'RoundTrip' &&
							JSON.parse(location.state.selectedFlight)[0]?.Segments[0]
								?.length > 0 &&
							JSON.parse(location.state.selectedFlight)[0]?.Segments[0]?.map(
								(i, index) => {
									return (
										<tr>
											<td style={{ padding: 15 }}>
												<span>{i.Airline.AirlineName}</span>
												<br />
												<span>
													{i.Airline.AirlineCode}
													{i.Airline.FlightNumber}
												</span>
											</td>
											<td style={{ padding: 15 }}>Economy </td>
											<td style={{ padding: 15 }}>
												{i.FareClassification.Type}{' '}
											</td>
											<td style={{ padding: 15 }}>Non-Stop </td>
											<td style={{ padding: 15 }}>{i.Baggage} </td>
											<td style={{ padding: 15 }}>{i.CabinBaggage} </td>
											<td style={{ padding: 15 }}>
												<span>{i.Origin.DepTime}</span>
												<br />
												<span>
													{i.Origin.Airport.CityName}(
													{i.Origin.Airport.AirportCode})
												</span>
											</td>

											<td style={{ padding: 15 }}>
												<span>{i.Destination.ArrTime}</span>
												<br />
												<span>
													{i.Destination.Airport.CityName}(
													{i.Destination.Airport.AirportCode})
												</span>{' '}
											</td>
											<td style={{ padding: 15 }}>
												{i.PaxType === 1
													? 'ADULT'
													: i.PaxType === 2
													? 'CHILD'
													: i.PaxType === 3
													? 'INFANT'
													: ''}
											</td>
										</tr>
									);
								}
							)}
						{location.state.journeyType === 'RoundTrip' &&
							JSON.parse(location.state.selectedFlight)[1]?.Segments[0]
								?.length > 0 &&
							JSON.parse(location.state.selectedFlight)[1]?.Segments[0]?.map(
								(i, index) => {
									return (
										<tr>
											<td style={{ padding: 15 }}>
												<span>{i.Airline.AirlineName}</span>
												<br />
												<span>
													{i.Airline.AirlineCode}
													{i.Airline.FlightNumber}
												</span>
											</td>
											<td style={{ padding: 15 }}>Economy </td>
											<td style={{ padding: 15 }}>
												{i.FareClassification.Type}{' '}
											</td>
											<td style={{ padding: 15 }}>Non-Stop </td>
											<td style={{ padding: 15 }}>{i.Baggage} </td>
											<td style={{ padding: 15 }}>{i.CabinBaggage} </td>
											<td style={{ padding: 15 }}>
												<span>{i.Origin.DepTime}</span>
												<br />
												<span>
													{i.Origin.Airport.CityName}(
													{i.Origin.Airport.AirportCode})
												</span>
											</td>

											<td style={{ padding: 15 }}>
												<span>{i.Destination.ArrTime}</span>
												<br />
												<span>
													{i.Destination.Airport.CityName}(
													{i.Destination.Airport.AirportCode})
												</span>{' '}
											</td>
											<td style={{ padding: 15 }}>
												{i.PaxType === 1
													? 'ADULT'
													: i.PaxType === 2
													? 'CHILD'
													: i.PaxType === 3
													? 'INFANT'
													: ''}
											</td>
										</tr>
									);
								}
							)}
					</tbody>
				</table>
			</Box> */}
		</Box>
	);
};

export default BookingDetailBus;
